export class Dimension {

        tube:any =true;// true,
        longueur:any ;// 1,
        diametre:any ;// 2,
        epaisseur:any;// 3,
        b:any ;// 4,
        H:any;// 5
        materiau:any
        static valide=false
}
