import {
  HttpClient,
  HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserPhoto } from '../class/user-photo';

const basePath = environment.basePath;
@Injectable({
  providedIn: 'root',
})
export class SiteService {
  partialData: any = {};
  constructor(private http: HttpClient) {}
  getSites(code: any) {
    return this.http.get(`${basePath}trancons_number?code_site=${code}`); //,{headers});
  }

  getInfoSites(code: any, latitude: any, longitude: any) {

    if (latitude && longitude && code)
      return this.http.get(
        `${basePath}data?code_site=${code}&latitude=${latitude}&longitude=${longitude}`
      ); //,{headers});

    if (latitude && longitude && !code)
      return this.http.get(
        `${basePath}data?latitude=${latitude}&longitude=${longitude}`
      ); //,{headers});

    return this.http.get(`${basePath}data?code_site=${code}`); 
  }

  setDetail(code: any, data: any, files: File[]) {
    console.log('code=', code);

    const formData: FormData = new FormData();

    formData.append('code_site', code);
    formData.append('data', JSON.stringify(data));

    for (var i = 0; i < files.length; i++) {
      if (files[i]) {
        console.log(i, files[i].type);
        const file = files[i];

        var b = new Blob([file], { type: 'text/plain;charset=utf-8' });
        const type = file.type;
        console.log('type===', type);
        var n: number = i + 1;
        console.log('photo_' + n);
        console.log('blob', b);

        formData.append('photo_' + n, b, file.name);
      }
    }
    console.log('data===', data);

    const req = new HttpRequest('POST', `${basePath}detailed_form`, formData);

    console.log(req);
    return this.http.request(req);
  }

  postDetail(code: any, data: any, files: UserPhoto[]) {
    console.log('code=', code);

    const formData: FormData = new FormData();

    formData.append('code_site', code);
    formData.append('data', JSON.stringify(data));

    for (var i = 0; i < files.length; i++) {
      if (files[i]) {
        console.log(i, files[i].type);
        const file = files[i];

        const type = file.type;
        console.log('type===', type);

        formData.append(file.name!, file.img!, file.name + '.png');
      }
    }
    console.log('data===', data);

    const req = new HttpRequest('POST', `${basePath}detailed_form`, formData);

    console.log(req);
    return this.http.request(req);
  }

  postPartialDetail(code: any, data: any) {
    console.log('DONE : code=', code);

    const formData: FormData = new FormData();

    formData.append('code_site', code);
    formData.append('data', JSON.stringify(data));

    const req = new HttpRequest('POST', `${basePath}detailed_form_partial`, formData);

    return this.http.request(req);
  }

  setSimple(code: any, data: any, files: File[]) {
    console.log('code=', code);

    const formData: FormData = new FormData();

    formData.append('code_site', code);
    formData.append('data', JSON.stringify(data));

    console.log('data===', data);
    const req = new HttpRequest('POST', `${basePath}simple_form`, formData);

    console.log(req);
    return this.http.request(req);
  }

  changeFile(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

 
}
