<div class="popup">
    <button class="close-btn" (click)="annulernontable()">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11" fill="white"/>
          <path d="M19 6L6 19M6 6L19 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
      
  
<div *ngIf="type=='atr'">
    <br>
    <br>
    <div class="row">

       

    <div class="normal-text" style="font-family: poppines bold;font-weight: bold;display: flex;">
    <label *ngIf="name=='mat_plaque'">Matériau plaque&nbsp;&nbsp;</label>
    <label *ngIf="name=='mat_tiges'">Matériau tiges&nbsp;&nbsp;</label>
    <label *ngIf="name=='nb_tiges'">Nombre de tiges&nbsp;&nbsp;</label>
    <label *ngIf="name=='ebride'">Epaisseur bride&nbsp;&nbsp;</label>
    <label *ngIf="name=='dbride'">Diamètre bride&nbsp;&nbsp;</label>
    <label *ngIf="name=='dtiges' && pp==1">Diamètre tige&nbsp;&nbsp;</label>
    <label *ngIf="name=='dtiges' && pp>1">Diamètre boulon&nbsp;&nbsp;</label>
    <label *ngIf="name=='drepartition'">Diamètre répartition&nbsp;&nbsp;</label>
    <label *ngIf="name=='mat_boulon'">Matériau boulons&nbsp;&nbsp;</label>
    <label *ngIf="name=='mat_bride'">Matériau bride&nbsp;&nbsp;</label>
    <label *ngIf="name=='nb_boulons'">Nombre de boulons&nbsp;&nbsp;</label>
    <label *ngIf="name=='bsup' && this.data.codesite!='FR-MQ-1056'">Bsup&nbsp;&nbsp;</label>
    <label *ngIf="name=='bsup' && this.data.codesite=='FR-MQ-1056'">Diamètre sup&nbsp;&nbsp;</label>
    <label *ngIf="name=='binf' && this.data.codesite!='FR-MQ-1056'">Binf&nbsp;&nbsp;</label>
    <label *ngIf="name=='binf' && this.data.codesite=='FR-MQ-1056'">Diamètre inf&nbsp;&nbsp;</label>
    <label *ngIf="name=='zsup'">Zsup&nbsp;&nbsp;</label>
    <label *ngIf="name=='zinf'">Zinf&nbsp;&nbsp;</label>




    <select  *ngIf="list"  class="custom-select classtd" id="request_type" name="tubeDiag"
    [(ngModel)]="valeur" >
    <option   *ngFor="let c of list " [value]="c">{{c}}</option>
    
     </select>
    <input matInput  [disabled]="this.atr" *ngIf="!list && name!='nb_tiges' && name!='nb_boulons'" type="number" min="0" onkeypress="return event.keyCode !== 45 && event.keyCode !== 43"   class="text" name="valeur" [(ngModel)]="valeur"><label style=""> 
        <div *ngIf="name!='nb_boulons' && name!='nb_tiges'&& name!='mat_plaque'&& name!='mat_tiges' && name!='mat_bride' && name!='mat_boulon' && name!='binf' && name!='bsup'  && name!='zinf' && name!='zsup'">(mm)</div>
        <div *ngIf="name!='nb_boulons' && name!='nb_tiges'&& name!='mat_plaque'&& name!='mat_tiges' && name!='mat_bride' && name!='mat_boulon' && (name=='binf' || name=='bsup'  || name=='zinf' || name=='zsup')">(m)</div>
    </label>
    <input matInput  [disabled]="this.atr" *ngIf="!list && (name=='nb_tiges' || name=='nb_boulons')"type="number" min="0" onkeypress="return event.keyCode !== 45 && event.keyCode !== 43 && event.keyCode !== 44 && event.keyCode !== 46" pattern="[0-9]+" pattern="[0-9]+"  class="text" name="valeur" [(ngModel)]="valeur">

    &nbsp;
    <button *ngIf="!this.atr" class="btn-x" (click)="this.atr=true"><i class="material-icons">done</i></button>   
    <button *ngIf="this.atr" class="btn-y" (click)="this.atr=false"><i class="material-icons">edit</i></button>
    
    
</div>
</div>
<div *ngIf="this.atr && (valeur=='' || valeur==null || valeur=='undefined')" >
    <br>
    <div style="color:red; display: flex;" >Veuillez saisir &nbsp;<div class="title">{{name}}</div>&nbsp; pour sauvegarder !</div>


</div>


</div>
<div *ngIf="type=='table'">
<button (click)="openPhoto()" class="btn btn-red" style="display: inline-flex" ><span class="material-icons">
    photo_camera
    </span> Prendre une photo</button>  
<div class="normal-text col-md-6" style="font-family: poppines bold;">
   &nbsp;&nbsp;
    <input matInput type="text" class="text" name="msg" [(ngModel)]="msg" style="border: none;margin-bottom: 20px;background: white;" disabled="true">
</div>


    <div>
                                <label class="title" style="font-size: x-large;
                                font-weight: bold;
                                color: #00658d;">{{name}}</label>
                                <hr></div>
                                <tbody  >
                                    <br>
                                    <tr>
                                        <th><a class="header" >Type&nbsp;</a> </th>
                                        <th>
                                            <select class="custom-select classtd" id="request_type" [disabled]="this.typeb" name="tubeDiag"
                                                [(ngModel)]="row.tube" (change)="onChangeDiagonales($event,row,'tube',i)">
                                                <option value=true>Tube</option>
                                                <option value=false>Cornière</option>
    
                                            </select>
                                            &nbsp;
                                            <button *ngIf="!this.typeb" class="btn-x" (click)="this.typeb=true"><i class="material-icons">done</i></button>
                                            <button *ngIf="this.typeb" class="btn-y" (click)="this.typeb=false"><i class="material-icons">edit</i></button>
                                        </th>
                                    </tr>
    <br>
                                    <tr>
                                        <th> <a class="header">Longueur&nbsp;</a> </th>
                                        <th style="display: flex;"><input matInput matInput [disabled]="this.longueur" type="text" class="tronc text " name="libelle"
                                            (change)="onChangeDiagonales($event,row,'longueur',i)" appFloatInput onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  
                                            event.charCode == 46 || event.charCode == 0 "
                                            [(ngModel)]="row.longueur"> <label style=""> (mm)</label>
                                            <button *ngIf="!this.longueur" class="btn-x" (click)="this.longueur=true"><i class="material-icons">done</i></button>
                                            <button *ngIf="this.longueur" class="btn-y" (click)="this.longueur=false"><i class="material-icons">edit</i></button>
                                        </th>
                                    </tr>
    <br>
                                    <tr *ngIf="row.tube=='true' && data.codesite!='FR-MQ-1056'">
                                        <th  > <a   class="header">Diamètre&nbsp;</a></th>
                                        <th  style="display: flex;"  ><input matInput [disabled]="this.diametre" type="v" class="text tronc" name="typeStruc"
                                            (change)="onChangeDiagonales($event,row,'diametre',i)" appFloatInput  onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  
                                            event.charCode == 46 || event.charCode == 0 "
                                            [(ngModel)]="row.diametre"> <label style="" >(mm)</label> 
                                            <button *ngIf="!this.diametre" class="btn-x" (click)="this.diametre=true"><i class="material-icons">done</i></button>
                                            <button *ngIf="this.diametre" class="btn-y" (click)="this.diametre=false"><i class="material-icons">edit</i></button>
                                         </th>
                                            
                                    </tr>
    <br *ngIf="row.tube=='true' && data.codesite!='FR-MQ-1056'">
                                    <tr>
                                        <th> <a class="header">Épaisseur&nbsp;</a></th>
                                        <th  style="display: flex;"><input matInput type="v" [disabled]="this.epaisseur" class="text tronc" name="region" appFloatInput onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  
                                            event.charCode == 46 || event.charCode == 0 "
                                            (change)="onChangeDiagonales($event,row,'epaisseur',i)"
                                            [(ngModel)]="row.epaisseur"><label style="">(mm)</label>
                                            <button *ngIf="!this.epaisseur" class="btn-x" (click)="this.epaisseur=true"><i class="material-icons">done</i></button>
                                            <button *ngIf="this.epaisseur" class="btn-y" (click)="this.epaisseur=false"><i class="material-icons">edit</i></button>
                                         </th>
                                    </tr>
    <br>
    
                                </tbody>
    
                                <tbody  >

                                    <tr *ngIf="row.tube=='false'">
                                        <th > <a  class="header">B</a> </th>
                                        <th style="display: flex;" ><input  matInput   [disabled]="this.b"class="text tronc" type="v" class="text tronc" name="adresse"
                                            (change)="onChangeDiagonales($event,row,'b',i)" appFloatInput onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  
                                            event.charCode == 46 || event.charCode == 0 "
                                            [(ngModel)]="row.b"><label style="" >(mm)</label>
                                            <button *ngIf="!this.b" class="btn-x" (click)="this.b=true"><i class="material-icons">done</i></button>
                                            <button *ngIf="this.b" class="btn-y" (click)="this.b=false"><i class="material-icons">edit</i></button>
                                        </th>
                                    </tr>
                               <br *ngIf="row.tube=='false'">
                                    <tr *ngIf="row.tube=='false'">
                                        <th > <a  class="header">L</a></th>
                                        <th style="display: flex;" ><input matInput [disabled]="this.h" class="text tronc"  type="v" class="text tronc" name="siteType" 
                                            (change)="onChangeDiagonales($event,row,'H',i)" appFloatInput onkeypress="return (event.charCode >= 48 && event.charCode <= 57) ||  
                                            event.charCode == 46 || event.charCode == 0 "
                                            [(ngModel)]="row.H"><label style="">(mm)</label>
                                            <button *ngIf="!this.h" class="btn-x" (click)="this.h=true"><i class="material-icons">done</i></button>
                                            <button *ngIf="this.h" class="btn-y" (click)="this.h=false"><i class="material-icons">edit</i></button>
                                        </th>
                                    </tr>
                              <br *ngIf="row.tube=='false'">
                                    <tr>
                                        <th> <a class="header" >Matériau</a></th>
                                        <th style="display: flex;" >
                                            <select [disabled]="this.mat" class="custom-select classtd" id="request_type" name="request_type"
                                                [(ngModel)]="row.materiau" 
                                                (change)="onChangeDiagonales($event,row,'materiau',i)">
                                                <option value="S355"> S355</option>
                                                <option value="S235"> S235</option>
    
                                            </select>
                                            &nbsp;
                                            <button *ngIf="!this.mat" class="btn-x" (click)="this.mat=true"><i class="material-icons">done</i></button>
                                            <button *ngIf="this.mat" class="btn-y" (click)="this.mat=false"><i class="material-icons">edit</i></button>
                                        </th>
                                    </tr>
    
        
                                </tbody>
                                <br>
                                <div *ngIf="row.tube=='false' && longueur && typeb && epaisseur && b && h && mat">
                                    <div *ngIf="row.longueur=='' || row.longueur==null " style="color:red; display: flex;" >Veuillez saisir Longueur pour sauvegarder !</div>
                                    <div *ngIf="row.epaisseur==''|| row.epaisseur==null" style="color:red; display: flex;" >Veuillez saisir Epaisseur pour sauvegarder !</div>
                                    <div *ngIf="row.b==''|| row.b==null" style="color:red; display: flex;" >Veuillez saisir B pour sauvegarder !</div>
                                    <div *ngIf="row.H==''|| row.H==null" style="color:red; display: flex;" >Veuillez saisir L pour sauvegarder !</div>
                                </div>
                                <div *ngIf="row.tube=='true' && diametre && longueur && epaisseur && typeb && mat">
                                    <div *ngIf="row.longueur==''|| row.longueur==null" style="color:red; display: flex;" >Veuillez saisir Longueur pour sauvegarder !</div>
                                    <div *ngIf="row.epaisseur==''|| row.epaisseur==null" style="color:red; display: flex;" >Veuillez saisir Epaisseur pour sauvegarder !</div>
                                    <div [hidden]="this.data.codesite=='FR-MQ-1056'" *ngIf="row.diametre==''|| row.diametre==null" style="color:red; display: flex;" >Veuillez saisir Diamètre pour sauvegarder !</div>
                                </div>
                                </div>


<div *ngIf="type=='table'">
    <div *ngIf="(row.tube=='false' && longueur && typeb && epaisseur && b && h && mat && row.longueur!='' && row.typeb!='' && row.epaisseur!='' && row.b!='' && row.H!='' && row.mat!=''
    && row.longueur!='undefined' && row.typeb!='undefined' && row.epaisseur!='undefined' && row.b!='undefined' && row.H!='undefined' && row.mat!='undefined') || 
                (row.tube=='true' && diametre && longueur && epaisseur && typeb && mat && row.longueur!='' && row.diametre!='' && row.epaisseur!='' && row.typeb!='' && row.mat!=''
                && row.longueur!='undefined' && row.diametre!='undefined' && row.epaisseur!='undefined' && row.typeb!='undefined' && row.mat!='undefined')" 
    class="button-wrapper col-md-12 sauvnotable"  >
        <button (click)="save()" style="background: #038595;
        color: white;
        font-weight: 400;" class="btn btn-red " >Sauvegarder</button>  
         <button [hidden]="true" type="reset" class="btn btn-blue"  style="color: #038595;
         border: none;
     ">Initialiser</button>
    </div>
</div>
<div *ngIf="type!='table' ">
    <div *ngIf="atr && valeur!='' && valeur!=null && valeur!='undefined' " class="button-wrapper col-md-12 sauvnotable"  >
        <button (click)="save()" style="background: #038595;
        color: white;
        font-weight: 400;" class="btn btn-red" >Sauvegarder</button>  
         <button [hidden]="true" type="reset" class="btn btn-blue"  style="color: #038595;
         border: none;
     ">Initialiser</button>

</div>
</div>