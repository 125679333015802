import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Dimension } from '../class/dimension';


import { PhotoService } from '../service/photo.service';
import { SiteService } from '../service/site.service';


//import{Camera} from '@capacitor/core'
@Component({
  selector: 'app-simple-att',
  templateUrl: './simple-att.component.html',
  styleUrls: ['./simple-att.component.css']
})


export class SimpleAttComponent implements OnInit {

  constructor(public photoService: PhotoService,public siteservice:SiteService,
    @Optional() public dialogRef: MatDialogRef<SimpleAttComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
public dialog: MatDialog) { }

  addPhotoToGallery() {
    this.photoService.addNewToGallery();
    this.msg="Image chargée"
  }
  pp!: number;
 name:any
 valeur :any
 photo:any
 msg:any
 row:any//Dimension
 i:number=0
 type:any
 list:any
 object = { 
  id : 0, 
  atr: '',
  valide : false 
}  
 initPartial: any = {
  trancons: [
    {
      
    }
  ]
};
  ngOnInit(): void {
    
    
 
    if(this.data){
      this.list=this.data.list
      this.name=this.data.atr
      this.type=this.data.type
      this.pp=this.data.pp
      if(this.type=='atr')
      this.valeur=this.data.val
      else if(this.type=='table')
      this.row=this.data.val
      
      
      for(var line of this.data.newobj){
        if(line.atr==this.name && line.id==this.pp-1){
          this.atr=true
          this.typeb=true
          this.longueur=true
          this.diametre=true
          this.epaisseur=true
          this.b=true
          this.h=true
          this.mat=true
        }
      }
      if(this.data.codesite=='FR-MQ-1056'){
        this.diametre=true
        this.row.diametre='0'
      }
    }
    
  }

  openPhoto(){
    this.photoService.init()
    this.addPhotoToGallery()
    
    if(this.photoService.photos[0])
    this.photo=this.photoService.photos[0].img
   
  }
 save(){
  var check = false
  this.object.id=this.pp-1
  this.object.atr=this.name
  this.object.valide=true

  for(var line of this.data.newobj){
    if(line.atr==this.object.atr && line.id == this.object.id)
    check=true
  }
  if(!check)
  this.data.newobj.push(this.object)


  

  var msg:string=''
  if(this.type=='table'){
  if(this.row.tube==true&& !this.row.diametre)
  msg=msg+" <br> <h1 style='display: inline-flex;margin-left: auto;'>Diamètre de "+this.name+"</h1>"
  if(this.row.tube==false&&!this.row.b)
  msg=msg+" <br> <h1 style='display: inline-flex;margin-left: auto;'>B de "+this.name+"</h1>"
  if(this.row.tube==false&&!this.row.H)
  msg=msg+" <br> <h1 style='display: inline-flex;margin-left: auto;'>L de "+this.name+"</h1>"
  
   if(!this.row.materiau)
  msg=msg+" <br> <h1 style='display: inline-flex;margin-left: auto;'>Matériaux de "+this.name+"</h1>"
 if(!this.row.epaisseur)
  msg=msg+" <br> <h1 style='display: inline-flex;margin-left: auto;'> Epaisseur de "+this.name+"</h1>"
  
     if(msg&& msg.length>1)
     Swal.fire('Erreur','Merci de renseigner ces éléments: <br>'+msg,'error')
     else
     {
      this.initPartial.trancons[0][this.name]=this.row
      this.initPartial.trancons[0].numero=Number(this.pp)
      this.siteservice.postPartialDetail(this.data.codesite,this.initPartial).subscribe(data=>{
        
      })
      //this.siteservice.updateGlobalObject(this.siteservice.partialData);
      
    Swal.fire("Valeur complétée","",'success')
    this.dialogRef.close ({data:this.row,img:this.photo,objectrow:this.data.newobj})
     }
    }

     else{
      if(this.valeur)
      {
        
        this.initPartial.trancons[0][this.name]=this.valeur
        this.initPartial.trancons[0].numero=Number(this.pp)
        this.siteservice.postPartialDetail(this.data.codesite,this.initPartial).subscribe(data=>{
          
        })
        Swal.fire("Valeur complétée","",'success')

        this.dialogRef.close ({data:this.valeur,objectrow:this.data.newobj})
      }
      else
      {
        Swal.fire('Erreur','Valeur incomplète','error')
      }
     }
 }



 onChangeDiagonales($event:any,row:any,col:any,i:number){

  
  
  
  //this.row.tube=$event.target.value
  
  
  
    }
    OnlyNumberAllowed(event:any){
      const charCode=(event.which)?event.which:event.keyCode;
      if(charCode>31&&(charCode<48)||(charCode>57)){
        return false; }
      return true
    }
    diametre:boolean=false
    longueur:boolean=false
    typeb:boolean=false
    epaisseur:boolean=false
    b:boolean=false
    h:boolean=false
    mat:boolean=false
    atr:boolean=false


validate($event:any,row:any,col:any,i:number){
  
}

annulernontable(){
  this.dialogRef.close()
}

annulertable(row:any){
  var check=false
  for(var line of this.data.newobj){
    if(line.atr==this.name)
    check=true
  }
  if(!check){
    row.longueur=''
    row.diametre=''
    row.epaisseur=''
    row.b=''
    row.H=''
  }

  this.dialogRef.close()
}

 validatee(evt:any) {
  var theEvent = evt || window.event;

  // Handle paste
  if (theEvent.type === 'paste') {
      key = evt.clipboardData.getData('text/plain');
  } else {
  // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
  }
  var regex = /[0-9]|\./;
  if( !regex.test(key) ) {
    theEvent.returnValue = false;
    if(theEvent.preventDefault) theEvent.preventDefault();
  }
}
}
