import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appFloatInput]'
})
export class FloatInputDirective {

  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement) {
    const value = input.value;
  
    // Remove any non-digit or non-dot characters
    const newValue = value.replace(/[^\d.]/g, '');
    
    // Limit the input to only one dot
    if (newValue.indexOf('.') !== -1 && newValue.lastIndexOf('.') !== newValue.indexOf('.')) {
      input.value = newValue.substr(0, newValue.lastIndexOf('.'));
      input.dispatchEvent(new Event('input'));
    }
  }

}
