import { Component, Inject, OnInit, Optional} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isEmpty } from 'rxjs';
import Swal from 'sweetalert2';
import { SimpleData } from '../class/simple-data';

@Component({
  selector: 'app-simple-data',
  templateUrl: './simple-data.component.html',
  styleUrls: ['./simple-data.component.css']
})
export class SimpleDataComponent implements OnInit {
  data:SimpleData =new SimpleData()
 
  constructor( 
    @Optional() public dialogRef: MatDialogRef<SimpleDataComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public sdata: any,
  public dialog: MatDialog ) { }

  ngOnInit(): void {
    console.log('this.sdata==',this.sdata.val)
    if(this.sdata&&this.sdata.val){
    this.data=this.sdata.val
    console.log('this.data',this.data)
    }
    else
    this.data=new SimpleData();
  }

  save(){
    if(this.data.Type=="" || this.data.Type==null)
    {
      Swal.fire('Le type est obligatoire','','warning')
    }
    else if(this.data.type_coax=="" || this.data.type_coax==null){
      Swal.fire('Le type coax est obligatoire','','warning')
    }

    else if(this.data.constructeur=="" || this.data.constructeur==null){
      Swal.fire('Constructeur est obligatoire','','warning')
    }
    else if(isNaN(this.data.hma) || this.data.hma==null){
      Swal.fire('HMA est obligatoire','','warning')
    }



    else{
    Swal.fire("Détails d’équipement sauvegardés","",'success')
    
    this.dialogRef.close ({sdata:this.data})
  }}
  annuler(){
    this.dialogRef.close()
  }
}


