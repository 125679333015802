export class SimpleData {
    Diametre:any// "",
    Type:any// Antennes_Radio",
    az:any// "",
    commentaire:any// "",
    constructeur:any// 6878300G",
    hma:any// 18,95",
    nb_coax:any// "",
    type_coax:any// 7/8\"
}
