import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';

const basePath = environment.basePath; 
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http:HttpClient) { }

  login(username:string,password:string){
    localStorage.clear();
    const headers = new HttpHeaders({ Authorization: 'Basic ' + btoa(username + ':' + password) });
 console.log(username + ':' + password,headers)
    return this.http.get(`${basePath}authentication`,{headers})
  }

  isLogged(): any {
          return localStorage.getItem('logged') ? true : false
           }

           isCurrentUser():any{
            return localStorage.getItem('CurrentUser') ? true : false

           }

           getSites():any
           {
            return JSON.parse(localStorage.getItem("sites") || "[]");
           }
  
 }
  
 
  