<!--LOGIN SECTION-->

        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div class="col-lg-12">
              <div class="card-body p-md-5 mx-md-4">
                <!--ARTATOWERS LOGO-->
                <div class="text-center" style="margin-bottom: 10px;">
                  <img class="img" width="10" src="../../assets/img/LOGOVT.jpg" alt="" >
                                  </div>
                    <!--LOGIN -->
                  <form id="accesspanel" action="">
   
                    <div class="inset" style="position: relative; margin-left: auto;margin-right: auto;width: fit-content;">
                      <p>
                        <input type="text" name="username" [(ngModel)]="username" id="email" placeholder="Nom d'utilisateur" class="input">
                      </p>
                      <p>
                        <input type="password" [type]="passwordVisible ? 'text' : 'password'" name="password" [(ngModel)]="password" id="password" placeholder="Mot de passe" class="input">

                         

                      </p>
                      <div class="password" *ngIf="passwordVisible==false" >
                        <input class="passwordicon" (click)="passwordVisible=true" type="checkbox"><label for="">&nbsp;&nbsp;Afficher le mot de passe</label>
                      </div>
                      <div class="password" *ngIf="passwordVisible==true" >
                        <input class="passwordicon" (click)="passwordVisible=false" checked type="checkbox"><label for="">&nbsp;&nbsp;Afficher le mot de passe</label>
                      </div>
                    </div>
                    <br>
                    <p class="p-container">
                      <input type="submit" name="Login" id="go" value="Connexion" (click)="doLogin()" class="btn">
                    </p>
                 </form>
               </div>
            </div>

          </div>
        </div>
 
