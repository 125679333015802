import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SiteComponent } from './site/site.component';
import { SimulationComponent } from './simulation/simulation.component';
import { SimpleAttComponent } from './simple-att/simple-att.component';


const routes: Routes = [
  {path:"",redirectTo:"site",pathMatch:"full"},
  {path:"login",component:LoginComponent},
  {path:"site",component:SiteComponent },
  {path:"simulation",component:SimulationComponent},
  {path:"simple-att",component:SimpleAttComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }