import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { UserPhoto } from '../class/user-photo';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public photos: UserPhoto[] = [];
  constructor() { }
  init(){
    this.photos=[]
  }
  public async addNewToGallery() {
    // Take a photo
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: true,
      source: CameraSource.Camera,
      quality: 90
    });
console.log('capturedPhoto',capturedPhoto)
    this.photos.unshift({
      filepath: "soon...",
      webviewPath: capturedPhoto.webPath,
      img:this.convert(capturedPhoto.base64String!),
        type: 'png',
        name:'test',
    });
   console.log('photo',this.photos)
  }
  public async  onClose()
  {
    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,

      source: CameraSource.Camera,
      quality: 100
    });

    
  }
  convert(base64String:any):Blob{
    console.log('ds ds ds ds')
    const rawData = atob(base64String);
    const bytes = new Array(rawData.length);
    for (var x = 0; x < rawData.length; x++) {
        bytes[x] = rawData.charCodeAt(x);
    }
    const arr = new Uint8Array(bytes);
    const blob = new Blob([arr], {type: 'image/png'});
    console.log('blob',blob)
    console.log('ds ds ds ds')
  return blob
  }
}
