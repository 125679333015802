<div class="container" >
    <h1 class="section-title" style="font-size: xx-large;font-weight: bold;color: #004560;">Ajout de détails équippement</h1>
    <p style="font-size: larger;"> Les champs marqués d'un  <span style="color: red;">*</span> sont obligatoires </p> 
<div class="card" style="margin: auto; ">
    <div class="card-body">
    <div class="row">
        <div class="col-md-6" >
         <div class="form-group">
            <label class="fwb" > <span style="color: red;">*</span> Type :</label><br>
            <select class="custom-select classtd" id="request_type" name="tubeDiag"
                                                [(ngModel)]="data.Type" >
                                                <option value="Antennes_Radio">Antennes_Radio</option>
                                                <option value="Antennes_FH">Antennes FH</option>
                                                <option value="Module_Radio">Module Radio</option>
                                                <option value="Module_Radio_autre">Module Radio autre</option>
                                                <option value="Echelles_et_cables">Echelles & cables</option>
                                                <option value="Paliers">Paliers</option>
                                                <option value="Autre">Autre</option>
    
                                            </select>
            
           </div>
        </div>
        <div class="col-md-6" >
            <div class="form-group">
            <label class="fwb" > <span style="color: red;">*</span> HMA :</label><br>
            <input type="number" [(ngModel)]="data.hma" class="custom-input" >  
            </div>  
        </div>
        
    </div>
	
	<div class="row">
        <div class="col-md-6" >
         <div class="form-group">
            <label class="fwb" >Dimension :</label><br>
            <input type="number" [(ngModel)]="data.Diametre"  class="custom-input" >
           </div>
        </div>
        <div class="col-md-6" >
            <div class="form-group">
            <label class="fwb" >AZ :</label><br>
            <input type="number" [(ngModel)]="data.az" class="custom-input" >  
            </div>  
        </div>
        
    </div>
	<div class="row">
        <div class="col-md-6" >
         <div class="form-group">
            <label class="fwb" > <span style="color: red;">*</span> Type coax :</label><br>
            <input type="text" [(ngModel)]="data.type_coax"  class="custom-input" >
           </div>
        </div>
        <div class="col-md-6" >
            <div class="form-group">
            <label class="fwb" >Nombre coax :</label><br>
            <input type="number" [(ngModel)]="data.nb_coax" class="custom-input" >  
            </div>  
        </div>
        
    </div>
	
	 <div class="row">
    <div class="col-md-12" >
        <div class="form-group">
        <label class="fwb" for="" > <span style="color: red;">*</span> Constructeur :</label><br>           
   
        <input type="text" [(ngModel)]="data.constructeur" class="custom-input"  >  
        </div>       
    </div>
    </div>
   
    <div class="row">
    <div class="col-md-12" >
        <div class="form-group">
        <label class="fwb" for="" > Commentaire :</label><br>           
   <textarea [(ngModel)]="data.commentaire" class="custom-input" style="width: fit-content;"> 

   </textarea >
   <br>
        
        </div>       
    </div>
    </div>
    <br>
            <div class="button-wrapper"  >
                <button (click)="save()" style="background: #038595;
                color: white;
                font-weight: 400;" class="btn btn-red" >Sauvegarder</button>  
                
                <button  (click)="annuler()" style="background: red;
                color: white;
                font-weight: 400; float:right" class="btn btn-red" >Fermer</button> 
            </div>

  
    </div>
   
   
    

</div>
            
       
    </div>

