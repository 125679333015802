import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PhotoService } from '../service/photo.service';
import { SimpleAttComponent } from '../simple-att/simple-att.component';

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {

  constructor(public photoService: PhotoService,
    @Optional() public dialogRef: MatDialogRef<SimpleAttComponent>,
  @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
public dialog: MatDialog) { }

  addPhotoToGallery() {
    this.photoService.addNewToGallery();
    this.msg="Image chargée"
  }

 name:any
 valeur :any
 photo:any
 msg:any
 

  ngOnInit(): void {
    this.addPhotoToGallery()
  }

}
