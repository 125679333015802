import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { SiteComponent } from './site/site.component';
import { SimulationComponent } from './simulation/simulation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { BasicAuthInterceptor } from './service/basic-auth.interceptor';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatDialogModule } from '@angular/material/dialog';
import { SimpleDataComponent } from './simple-data/simple-data.component';
import { SimpleAttComponent } from './simple-att/simple-att.component';
import { TableAttComponent } from './table-att/table-att.component';
import { Camera, CameraResultType } from '@capacitor/camera';
import { TabsComponent } from './tabs/tabs.component';
import { PhotoComponent } from './photo/photo.component';
import { environment } from 'src/environments/environment.prod';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import { FloatInputDirective } from './float-input.directive';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

//import { Camera, CameraResultType } from '@capacitor/camera';



const takePicture = async () => {
  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: true,
    resultType: CameraResultType.Uri
  });

  // image.webPath will contain a path that can be set as an image src.
  // You can access the original file using image.path, which can be
  // passed to the Filesystem API to read the raw data of the image,
  // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
  var imageUrl = image.webPath;

  // Can be set to the src of an image now
  //imageElement.src = imageUrl;
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SiteComponent,
    SimulationComponent,
    SimpleDataComponent,
    SimpleAttComponent,
    TableAttComponent,
    TabsComponent,
    PhotoComponent,
    FloatInputDirective,
   
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatCardModule,
    NgbModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    NgxPaginationModule,
    MatDialogModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatSlideToggleModule
    
    
    
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthInterceptor,
      multi: true,
    },
  ],
 
  bootstrap: [AppComponent]
})
export class AppModule { }