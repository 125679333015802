import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Optional, Output, Pipe, PipeTransform, VERSION, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Data } from '../class/data';
import { Dimension } from '../class/dimension';
import { Trancons } from '../class/trancons';
import { SiteService } from '../service/site.service';
import { SimpleDataComponent } from '../simple-data/simple-data.component';
import { saveAs } from 'file-saver';
import { map, Observable, startWith, switchAll } from 'rxjs';
import Swal from 'sweetalert2';
import { SimpleAttComponent } from '../simple-att/simple-att.component';
import { PhotoService } from '../service/photo.service';
import { UserPhoto } from '../class/user-photo';
import { TabsComponent } from '../tabs/tabs.component';
import { PhotoComponent } from '../photo/photo.component';
import { CanDeactivate, ɵassignExtraOptionsToRouter } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { Form, FormControl } from '@angular/forms';
import { SimpleData } from '../class/simple-data';
import * as _ from 'lodash';

@Component({
  selector: 'app-site',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss']
})

export class SimulationComponent implements OnInit   {
  image: any[] = []
  style: any[] = []
  class: any[] = []
  pp: number = 1;
  run: boolean = false
  latitude: any
  longitude: any
  collection!: any[]
  materieu!: string
  // p:any
  
  p2: any = 1
  methode: any
  sdatas: any[] = []
  images: UserPhoto[] = [];
  mattiges = ['4.6', '4.8', '5.6', '5.8', '6.8', '8.8', '10.9']
  matplaque = ['S355', 'S235']
  vueMem: boolean = false
  showMethode: any = true
  hauteur: any
  @Input() id!: string;
  @Input() maxSize!: number;
  @Output() pageChange!: EventEmitter<number>;
  @Output() pageBoundsCorrection!: EventEmitter<number>;
  @ViewChild('validation') validation: any;

  myControl = new FormControl('');
  stateCtrl!: FormControl;
  filteredStates!: Observable<any[]>;
  states: string[] = this.authService.getSites().codes_site
  adress!: any
  department!: any
  hms: boolean = false
  lf: boolean = false
  hf: boolean = false
  h: boolean = false
  a: boolean = false
  b: boolean = false
  elu: boolean = false
  els: boolean = false
  selectmethode = false
  validated:boolean=false 
  constructor(public photoService: PhotoService,
    private siteService: SiteService,
    public dialog: MatDialog,
    public authService: AuthenticationService
  ) {

  }

  
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
      if (this.methode=='detail' && this.validated==false) {
          $event.returnValue =true;
      }
  }
  
  username: any = 'username';
  password: string = 'password';
  gpsrequired:any
  base!: any
  code: any
  nbr: any
  data: Data = new Data()
  photo: any[] = [];
  tabs: any = -1
  sites: any[] = []


  
  ngOnInit(): void {

    this.username=localStorage.getItem('username')
    this.gpsrequired=localStorage.getItem('gpsrequired')
    console.log(this.username,this.gpsrequired)
    //this.gpsrequired='true'
    
 
    this.selectimage()
    this.stateCtrl = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges
      .pipe(
        startWith(''),
        map(state => state ? this.filterStates(state) : this.states.slice())
      );

  }



  filterStates(name: string) {
    return this.states.filter(state =>
      state.toString().indexOf(name) === 0);
  }


  selectedFiles?: FileList;
  onChangeFile($event: any, i: number) {
    //console.log(i,'file change',$event.target.files)
    this.selectedFiles = $event.target.files;
    if (this.selectedFiles && this.selectedFiles?.length > 0)

      this.photo[i] = this.selectedFiles[0]
    // this.selectedFiles = event.target.files;
  }

  newdas = {
    "binf": "",
    "bsup": "",
    "dbride": "",
    "diagonales": {
      "H": "5.6",
      "b": "4.5",
      "diametre": "",
      "epaisseur": "",
      "longueur": "",
      "materiau": "S355",
      "tube": "true"
    },
    "drepartition": "",
    "dtiges": "",
    "ebride": "",
    "mat_boulon": "",
    "mat_bride": "",
    "mat_plaque": "",
    "mat_tiges": "",
    "membrures": {
      "H": "",
      "b": "",
      "diametre": "",
      "epaisseur": "",
      "longueur": "",
      "materiau": "S355",
      "tube": "true"
    },
    "nb_boulons": "",
    "nb_tiges": "",
    "numero": 1,
    "traverses": {
      "H": "",
      "b": "",
      "diametre": "",
      "epaisseur": "",
      "longueur": "",
      "materiau": "S355",
      "tube": "true"
    },
    "zinf": "",
    "zsup": ""
  }

  downloadFile(doc: Blob, name: any): void {
    //this.documentService.pullById(doc.id).subscribe(blob => saveAs(blob, doc.name));
    saveAs(doc, name)
  }
  newnbr!: any
  editnbr() {

  }

  getNbr() {
    if(!this.code){
      Swal.fire({
        title: 'Code site est invalide.',
        icon: 'error',
        width: 300,
        padding: '3em',
      })
      return
    }
    this.clearcache()
    //this.gps = false
    this.methode = false
    //this.methode=""
    this.selectmethode = false
    this.s = false
    this.msg = ''
    this.images = []
    this.tabs = -1
    this.run = true
    if(!this.gps){
      this.longitude = null
      this.latitude = null
    }


    this.siteService.getInfoSites(this.code, this.latitude, this.longitude).subscribe((data: any) => {
      var newobject:any[] =data.data.formulaire_detaillé.trancons
      console.log(Object.keys(newobject).length, "nombre de tronçons")
      var oldnumber:any = Object.keys(newobject).length
      data.nombre_troncon=oldnumber
      if (data.nombre_troncon > 0 )
        this.newnbr = oldnumber

      const dialogRef = this.dialog.open(this.validation, { disableClose: true })
      dialogRef.afterClosed().subscribe((result: any) => {

        this.photoService.init();
        this.selectmethode = true
        this.showMethode = true
        if (this.newnbr != data.nombre_troncon && this.newnbr >= data.nombre_troncon) {

          var diff = this.newnbr-data.nombre_troncon
          var lst: Trancons[] = []
          var sd: any[] = []
          


          lst = data.data.formulaire_detaillé.trancons
          lst.length=data.nombre_troncon
          for(var i=1 ; i<=diff ; i++){
            var tr: any = _.cloneDeep(this.newdas);
            tr.numero=data.nombre_troncon+i
            lst.push(tr);
          }
          
          console.log("newdas",lst)

          this.data.trancons = lst
          data.data.formulaire_simplifie = sd
          data.data.trancons = lst
          data.data.formulaire_detaillé.trancons = lst
          data.nombre_troncon = this.newnbr
        }else{
          lst = data.data.formulaire_detaillé.trancons
          lst.length=this.newnbr
          data.nombre_troncon = this.newnbr
        }
        this.nbr = data.nombre_troncon
        this.hauteur = data.hauteur + " (m)"
        this.adress = data.adresse
        this.department = data.departement
        this.base = this.nbr
        this.s = true

        if (data && data.data && data.data.formulaire_simplifie) {
          this.sdatas = data.data.formulaire_simplifie
        }

        if (data && data.data && data.data.formulaire_detaillé) {
          this.data = data.data.formulaire_detaillé



        } else {
          var lst: Trancons[] = []
          for (let r = 0; r < this.nbr; r++) {
            //this.photo.push()//(src+r+'.png')
            tr = new Trancons();
            tr.numero = this.nbr - r
            tr.diagonales = new Dimension()
            tr.membrures = new Dimension()
            tr.traverses = new Dimension()
            lst[r] = tr

          }
          this.data.trancons = lst

        }
        //console.log(this.data.trancons,"lst")

      })
      console.log(data,"final data")
    })


    this.siteService.getInfoSites(this.code, this.latitude, this.longitude
    ).subscribe((data: any) => {



    });

  }


  validaterow: any[] = []
  openAttr(atr: any, n: number) {
    var i = n - 1
    var list: any
    if (atr == 'mat_tiges')
      list = this.mattiges
    if (atr == 'mat_plaque')
      list = this.matplaque
    if (atr == 'mat_boulon')
      list = this.mattiges
    if (atr == 'mat_bride')
      list = this.matplaque



    //console.log(this.validaterow,"list of validation")

    const dialogRef = this.dialog.open(SimpleAttComponent, {

      data: { atr: atr, type: 'atr', val: this.data.trancons[i][atr], list: list, newobj: this.validaterow, pp: n },
      disableClose: true

    })
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{
      console.log('res from simle data', result)
      //console.log('res from service',this.photoService.photos)
      this.sdatas.push(result.data)
      if (result)
        this.data.trancons[i][atr] = result.data

    })
  }

  openTable(atr: any, n: number) {
    var i = n - 1
    console.log(i,"numbner")

    const dialogRef = this.dialog.open(SimpleAttComponent, {

      data: { atr: atr, type: 'table', val: this.data.trancons[i][atr], newobj: this.validaterow, pp: n },
      disableClose: true

    })
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{
      //console.log('res from simle data',result)
      //console.log('res from service',this.photoService.photos)
      //this.sdatas.push(result.data)
      if (result)
        this.data.trancons[i][atr] = result.data
      if (this.photoService.photos[0]?.img) {
        var ph = new UserPhoto()
        var nbr = i + 1
        ph.name = atr + '_' + nbr
        ph.type = "png"
        ph.img = this.photoService.photos[0]?.img
        // this.downloadFile(ph.img!,ph.name)
        this.images.push(ph)
      }
    })
  }

  onChangeDiagonales($event: any, row: any, col: any, i: number) {

    //console.log('value',$event.target.value)
    //console.log('col',col)

    this.data.trancons[i][col] = $event.target.value

  }

  validerBase() {
    console.log(typeof (this.data.a))
    console.log(typeof (this.data.b))
    if (this.hms && this.lf && this.hf && this.h && this.a && this.b ) {
      var msg: any = ''
      if (!this.data.hms)
        msg += " <br> " + 'Hms'
      if (!this.data.lf)
        msg += " <br> " + 'Lf'
      if (!this.data.hf)
        msg += " <br> " + 'Hf'
      if (!this.data.h)
        msg += " <br> " + 'H'
      if (!this.data.a)
        msg += " <br> " + 'A'
      if (!this.data.b)
        msg += " <br> " + 'B'


      //console.log('messge base msg====',msg)
      if (msg && msg.length > 0)

        // Swal.fire('Erreur','Merci de renseigner ces éléments:'+msg,'error')
        Swal.fire({
          title: 'Merci de renseigner ces éléments: ' + msg,
          icon: 'warning',
          width: 400,
          padding: '3em',
        })


      else {
        this.data.valideBase = true
        this.tabs = -1
        //  Swal.fire('Fondation validée ','','success')
        Swal.fire({
          title: 'Fondation validée',
          icon: 'success',
          width: 400,
          padding: '3em',
        })
      }
    } else {
      Swal.fire({
        title: 'Tous les champs marqués par * doivent être validés',
        icon: 'error',
        width: 400,
        padding: '3em',
      })
    }



  }

  valider() {

    // this.data.trancons.length-=1
    //console.log(JSON.stringify(this.data),this.data)
    //console.log("images===",this.images)
    var err: string = ''
    var conf: string = ''
    var env: boolean = false
    if (this.methode == 'detail') {
      if (!this.data.valideBase) {
        err = "<br> <h1 style='display: inline-flex;margin-left: auto;'>" + 'Fondation' + "</h1>"
      }

      for (var i = 0; i < this.data.trancons.length; i++) {
        //console.log('troncon'+i,this.data.trancons[i].valide)
        var nbr = this.data.trancons.length - i
        if (!this.data.trancons[i].valide)
          err = err + " <h1 style='margin-left: auto;'>" + 'T' + nbr + "</h1>"
      }
      if (err && err.length > 0) {
        env = true;
        //Swal.fire('Erreur','Vous devez valider:'+err,'error')
        Swal.fire({
          title: 'Merci de valider :' + err,
          icon: 'error',
          width: 300,
          padding: '3em',
        })
      }
      else
        this.siteService.postDetail(this.code, this.data, this.images).subscribe((val: any) => {
          console.log("val ds send", val)
          conf = 'Formulaire détaillé envoyé avec succès'
          this.data.valide = true
          console.log(this.data)
          this.validated=true
        },
          (error: { error: { message: any; }; }) => {
            //this.loginError=true;
            //console.log(error, 'errrrrrrreur1')
            //console.log(error.error.message, 'errrrrrrreur')
            //console.log("Ierreur", "", 'error')
            err = 'Erreur au niveau de serveur:' + error.error.message
            //  Swal.fire('Erreur','','error')
          }
        );
    } else if (this.methode == 'simple') {
      this.siteService.setSimple(this.code, this.sdatas, this.photo).subscribe(val => {
        //console.log("val ds send",val)
        conf = 'Formulaire simplifié envoyé avec succès'
        //this.methode=""
      },
        error => {
          //this.loginError=true;
          //console.log(error, 'errrrrrrreur1')
          //console.log(error.error.message, 'errrrrrrreur')
          //console.log("Ierreur", "", 'error')
          err = 'Erreur au niveau de serveur:' + error.error.message
        }
      );
    }
    if (!env) {
      if (err && err.length > 0)
        //Swal.fire('Erreur',err,'error')
        Swal.fire({
          title: err,
          icon: 'error',
          width: 300,
          padding: '3em',
        })
      else if (conf && conf.length > 0)
        // Swal.fire('',conf,'success')
        Swal.fire({
          title: conf,
          icon: 'success',
          width: 300,
          padding: '3em',
        })
    }
  }

  openSimpleData() {
    const dialogRef = this.dialog.open(SimpleDataComponent, {

      data: {},
      disableClose: true

    })
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{
      //console.log('res from simle data',result)
      if (result)
        this.sdatas.push(result.sdata)
    })

  }

  openDialogEdit(row: any) {
    const dialogRef = this.dialog.open(SimpleDataComponent, {

      data: { val: row },
      disableClose: true

    })
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{
      //console.log('res from editsimle data',result)
    })

  }
  changeFile($event: any) {

  }
  upload() {

  }
  visibilityy: boolean = false
  s: boolean = false
  vue: boolean = true
  showBase: boolean = false
  visibility() {
    // this.visibilityy=true
    //  this.visibilityy=!this.visibilityy
    //console.log("visibilty ",this.visibilityy)
  }
  onchangeModel(pp: any, $event: any) {
    this.vue = true
    this.tabs = pp
    this.vue = true
    this.vueIndx = true
    this.pp = pp
    if (pp == -1) {
      this.showBase = true
    }
  }

  pylone() {
    this.tabs = -1
  }
  infos() {
    this.tabs = -2
  }
  msg: any
  addPhotoToGallery() {
    this.photoService.addNewToGallery();
    this.msg = "Image chargée"
  }
  photoPylone: any

  openPhotoBr(n: number) {
    var i = n - 1
    this.photoService.init()
    const dialogRef = this.dialog.open(PhotoComponent, {
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {
        if (this.photoService.photos[0]?.img) {
          var ph = new UserPhoto()
          var nbr = i + 1
          ph.name = 'bride_' + nbr
          ph.type = "png"
          ph.img = this.photoService.photos[0]?.img
          // this.downloadFile(ph.img!,ph.name)
          this.images.push(ph)
        }
      }

    })
  }
  openPhotoTr(n: number) {
    var i = n - 1
    this.photoService.init()
    const dialogRef = this.dialog.open(PhotoComponent, {
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {
        if (this.photoService.photos[0]?.img) {
          var ph = new UserPhoto()
          var nbr = i + 1
          ph.name = 'troncon_' + nbr
          ph.type = "png"
          ph.img = this.photoService.photos[0]?.img
          // this.downloadFile(ph.img!,ph.name)
          this.images.push(ph)
        }
      }

    })

    //console.log('lst photo service',this.photoService.photos[0])
  }
  openPhoto() {
    this.photoService.init()
    const dialogRef = this.dialog.open(PhotoComponent, {
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {
        if (this.photoService.photos[0]?.img) {
          var ph = new UserPhoto()
          ph.name = 'site'
          ph.type = "png"
          ph.img = this.photoService.photos[0]?.img
          // this.downloadFile(ph.img!,ph.name)
          this.images.push(ph)
        }
      }

    })

    //console.log('lst photo service',this.photoService.photos[0])
  }

  openPhotoBase() {
    this.photoService.init()
    const dialogRef = this.dialog.open(PhotoComponent, {
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {

        var ph = new UserPhoto()

        ph.name = 'base'
        ph.type = "png"
        ph.img = this.photoService.photos[0]?.img
        // this.downloadFile(ph.img!,ph.name)
        this.images.push(ph)
      }

    })

    //console.log('lst photo service',this.photoService.photos[0])
  }
  validerTr(i: number) {
    //var i=n-1
    //console.log('i===',i)
    console.log(this.data.trancons[i])
    var msg: any = ''
    if (!this.data.trancons[i]?.dtiges)
      msg = " <br> <h1 style='display: inline-flex;margin-left: auto;'>" + 'Dtiges' + "</h1>"
    if (!this.data.trancons[i]?.dbride)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'>" + 'Dbride' + "</h1>"

    if (!this.data.trancons[i]?.ebride)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'>" + 'Ebride' + "</h1>"
    if (!this.data.trancons[i]?.diagonales.materiau)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'>" + 'Matériaux de diagonale' + "</h1>"
    if (!this.data.trancons[i]?.membrures.materiau)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'>" + 'Matériaux de membrures' + "</h1>"
    if (!this.data.trancons[i]?.traverses.materiau)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'>" + 'Matériaux de traverses' + "</h1>"


    if (!this.data.trancons[i]?.diagonales.epaisseur)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'> Epaisseur de diagonale" + "</h1>"
    if (!this.data.trancons[i]?.membrures.epaisseur)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'> Epaisseur de membrures" + "</h1>"
    if (!this.data.trancons[i]?.traverses.epaisseur)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'> Epaisseur de traverses" + "</h1>"

    //console.log('this.data.trancons['+i+']?.diagonales.tube',this.data.trancons[i]?.diagonales.tube)
    if (this.data.trancons[i]?.diagonales.tube == true && !this.data.trancons[i]?.diagonales.diametre)
      msg = msg + " <br> <h1 style='display: inline-flex;margin-left: auto;'> Diamètre de diagonale" + "</h1>"
    if (this.data.trancons[i]?.membrures.tube == true && !this.data.trancons[i]?.membrures.diametre)
      msg = msg + "<br> <h1 style='display: inline-flex;margin-left: auto;'> Diamètre de membrures" + "</h1>"
    if (this.data.trancons[i]?.traverses.tube == true && !this.data.trancons[i]?.traverses.diametre)
      msg = msg + "<br> <h1 style='display: inline-flex;margin-left: auto;'> Diamètre de traverses" + "</h1>"


    //console.log('messge msg===='+i+'----'+this.data.trancons[i]?.ebride,msg)
    if (msg && msg.length > 1)
      // Swal.fire('Erreur','Merci de renseigner ces éléments:'+msg,'error')
      Swal.fire({
        title: 'Merci de renseigner ces éléments:' + msg,
        icon: 'error',
        width: 300,
        padding: '3em',
      })
    else {
      this.data.trancons[this.pp - 1].valide = true
      Swal.fire('Tronçon validé ', '', 'success')

      this.tabs = -1
    }

  }
  selectimage() {
    var b = 50

    var j = 1
    var c2 = 2
    var c = "tr{margin-left:"
    var c1 = "%;}"
    for (var i = 1; i <= 15; i++) {
      var s = "../../assets/img/P_"
      var s1 = ".png"
      var a = "width: "
      var a2 = "px;"
      this.image.push(s + j + s1)
      this.style.push(b)
      this.class.push(c + c2 + c1)
      c2 += 2
      b = b*1.1666
      j++
      if (j == 5) {
        j = 1
      }

    }

    console.log(this.style)
  }

  onChangeMethode($event: any) {

    this.methode = $event.target.value
    //console.log('onChangeMethode ',this.methode)
    this.showMethode = true
  }
  vuDiag: boolean = false
  vuTraf: boolean = false

  index: any
  vueIndx: boolean = false

  openStepper(i: any) {
    console.log(this.pp,"fdfdfdf")
    console.log(i,"psst")
    this.tabs = i
    this.vue = true
    this.index = i
    this.vueIndx = true


    if (i == -2) {
      this.showBase = true
    }
    if (i > -1) {
      //this.pp=i
      this.tabs = i
    }
  }
  retour() {
    this.vue = true
    this.vueIndx = false

  }


  name = 'Angular ' + VERSION.major;
  isZoomed = false;
  pos = { top: 0, left: 0, x: 0, y: 0 };
  @ViewChild('img') 'img': ElementRef;
  @ViewChild('container') 'container': ElementRef;
  onClick(e: any) {
    //console.log(e.clientY, e.clientX);
    this.isZoomed = !this.isZoomed;
    if (this.isZoomed) {
      this.container.nativeElement.style.overflow = 'hidden';
      this.img.nativeElement.style.width = '200%';
      this.img.nativeElement.style.cursor = 'zoom-out';
      this.img.nativeElement.style.cursor = 'zoom-out';
      this.img.nativeElement.style.left = `-${e.clientX}`;
      this.img.nativeElement.style.top = `-${e.clientY}`;
    } else {
      this.container.nativeElement.style.overflow = 'hidden';
      this.img.nativeElement.style.width = '100%';
      this.img.nativeElement.style.cursor = 'zoom-in';
    }
  }
  onMouseDown(e: any) {
    this.pos = {
      // The current scroll
      left: this.container.nativeElement.scrollLeft,
      top: this.container.nativeElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
  }

  mouseMoveHandler(e: any) {
    // How far the mouse has been moved
    const dx = (e.clientX - this.pos.x) * 2;
    const dy = (e.clientY - this.pos.y) * 3;

    // Scroll the element
    this.container.nativeElement.scrollTop = this.pos.top - dy;
    this.container.nativeElement.scrollLeft = this.pos.left - dx;
  }

  onLeave() {
    this.container.nativeElement.style.overflow = 'hidden';
    this.img.nativeElement.style.transform = 'scale(1)';
    this.img.nativeElement.style.cursor = 'zoom-in';
  }


  x: any //= document.getElementById("demo");
  gps: boolean = false

  getLocation() {
    this.methode = null
    this.clearcache()
    this.code = null
    this.selectmethode = false
    this.s = false
    /* if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(this.showPosition);
       //console.log("x===",this.x)
 
       
     } */
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        var lat = position?.coords?.latitude
        //  this.latitude=lat
        //console.log('lat',position?.coords.latitude)
        var long = position?.coords?.longitude
        //this.longitude= long;
        //console.log("position:" +lat+'--'+long)//,this.longitude,this.longitude)
        this.getNbrByCoord(lat, long)
        if (!isNaN(long) && !isNaN(lat)) {
          this.gps = true
          this.run = true
          this.longitude = long
          this.latitude = lat
        }

        //console.log("position==",position)
      }
      );

    }
  }

  showPosition(position: any) {
    //console.log("pos",position)
    var lat = position?.coords?.latitude
    //  this.latitude=lat
    //console.log('lat',position?.coords.latitude)
    var long = position?.coords?.longitude
    //this.longitude= long;
    //console.log("position:" +lat+'--'+long)//,this.longitude,this.longitude)
    // this.getNbrByCoord(lat,long)
    this.msg = ''
    this.images = []
    this.tabs = -1
    this.run = true

    this.siteService.getInfoSites(this.code, lat, long).subscribe((data: any) => {
      this.photoService.init();

      //console.log('data nbr tronçon',data)
      this.showMethode = true
      this.nbr = data.nombre_troncon
      this.hauteur = data.hauteur + " (m)"
      this.base = this.nbr
      this.s = true

      if (data && data.data && data.data.formulaire_simplifie) {
        this.sdatas = data.data.formulaire_simplifie
      }

      if (data && data.data && data.data.formulaire_detaillé) {
        this.data = data.data.formulaire_detaillé



      } else {
        var lst: Trancons[] = []
        for (let r = 0; r < this.nbr; r++) {
          //this.photo.push()//(src+r+'.png')
          var tr = new Trancons();
          tr.numero = this.nbr - r
          tr.diagonales = new Dimension()
          tr.membrures = new Dimension()
          tr.traverses = new Dimension()
          lst[r] = tr

        }
        this.data.trancons = lst

      }
      //console.log(this.data.trancons,"lst")


    });

  }

  getNbrByCoord(lat: any, long: any) {

    //this.methode=""
    this.msg = ''
    this.images = []
    this.tabs = -1
    this.run = true
    //console.log('pos nbr'+lat,long,this.code)
    this.siteService.getInfoSites(this.code, lat, long).subscribe((data: any) => {

      this.newnbr = data.nombre_troncon
      const dialogRef = this.dialog.open(this.validation, { disableClose: true })
      dialogRef.afterClosed().subscribe((result: any) => {

        this.photoService.init();
        this.selectmethode = true
        if (this.newnbr != data.nombre_troncon && this.newnbr >= data.nombre_troncon) {

          var diff = this.newnbr-data.nombre_troncon
          var lst: Trancons[] = []
          var sd: any[] = []
          


          lst = data.data.formulaire_detaillé.trancons
          lst.length=data.nombre_troncon
          for(var i=1 ; i<=diff ; i++){
            var tr: any = _.cloneDeep(this.newdas);
            tr.numero=data.nombre_troncon+i
            lst.push(tr);
          }
          
          console.log("newdas",lst)

          this.data.trancons = lst
          data.data.formulaire_simplifie = sd
          data.data.trancons = lst
          data.data.formulaire_detaillé.trancons = lst
          data.nombre_troncon = this.newnbr
        }else{
          lst = data.data.formulaire_detaillé.trancons
          lst.length=this.newnbr
          data.nombre_troncon = this.newnbr
        }

        //console.log('data nbr tronçon',data)
        this.showMethode = true
        this.nbr = data.nombre_troncon
        this.hauteur = data.hauteur + " (m)"
        this.base = this.nbr
        this.adress = data.adresse
        this.department = data.departement
        //console.log(this.adress,this.department)
        this.s = true
        this.code = data.code_site

        if (data && data.data && data.data.formulaire_simplifie) {
          this.sdatas = data.data.formulaire_simplifie
        }

        if (data && data.data && data.data.formulaire_detaillé) {
          this.data = data.data.formulaire_detaillé



        } else {
          var lst: Trancons[] = []
          for (let r = 0; r < this.nbr; r++) {
            //this.photo.push()//(src+r+'.png')
            tr = new Trancons();
            tr.numero = this.nbr - r
            tr.diagonales = new Dimension()
            tr.membrures = new Dimension()
            tr.traverses = new Dimension()
            lst[r] = tr

          }
          this.data.trancons = lst

        }
        //console.log(this.data.trancons,"lst")

      })





    });

  }

  allvalid(data: any): boolean {
    return true

  }

  clearcache() {
    //this.image=[]
    this.run = false
    this.sdatas = []
    this.images = [];
    this.selectmethode = false
    this.sites = []
    this.newnbr = null
  }

  //if gpsrequired buttons behavior
  gpscodesite(){
    this.methode = null
    this.clearcache()
    this.code = null
    this.selectmethode = false
    this.s = false
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        var lat = position?.coords?.latitude
         this.latitude=lat
        //console.log('lat',position?.coords.latitude)
        var long = position?.coords?.longitude
        this.longitude= long;
        //console.log("position:" +lat+'--'+long)//,this.longitude,this.longitude)
        this.siteService.getInfoSites(this.code, lat, long).subscribe((data: any) => {
          this.code=data.code_site
          if (!isNaN(long) && !isNaN(lat)) {
            this.gps = true
            this.run = true
            this.longitude = long
            this.latitude = lat
          }
        })
      }
      );

    }
  }

  validergps(){
    if(!this.code){
      Swal.fire({
        title: 'Code site est invalide.',
        icon: 'error',
        width: 300,
        padding: '3em',
      })
      return
    }
    this.msg = ''
    this.images = []
    this.tabs = -1
    this.run = true
    this.siteService.getInfoSites(this.code, null, null).subscribe((data: any) => {

      this.newnbr = data.nombre_troncon
      const dialogRef = this.dialog.open(this.validation, { disableClose: true })
      dialogRef.afterClosed().subscribe((result: any) => {

        this.photoService.init();
        this.selectmethode = true
        if (this.newnbr != data.nombre_troncon && this.newnbr >= data.nombre_troncon) {

          var diff = this.newnbr-data.nombre_troncon
          var lst: Trancons[] = []
          var sd: any[] = []
          


          lst = data.data.formulaire_detaillé.trancons
          lst.length=data.nombre_troncon
          for(var i=1 ; i<=diff ; i++){
            var tr: any = _.cloneDeep(this.newdas);
            tr.numero=data.nombre_troncon+i
            lst.push(tr);
          }
          
          console.log("newdas",lst)

          this.data.trancons = lst
          data.data.formulaire_simplifie = sd
          data.data.trancons = lst
          data.data.formulaire_detaillé.trancons = lst
          data.nombre_troncon = this.newnbr
        }else{
          lst = data.data.formulaire_detaillé.trancons
          lst.length=this.newnbr
          data.nombre_troncon = this.newnbr
        }

        //console.log('data nbr tronçon',data)
        this.showMethode = true
        this.nbr = data.nombre_troncon
        this.hauteur = data.hauteur + " (m)"
        this.base = this.nbr
        this.adress = data.adresse
        this.department = data.departement
        //console.log(this.adress,this.department)
        this.s = true
        this.code = data.code_site

        if (data && data.data && data.data.formulaire_simplifie) {
          this.sdatas = data.data.formulaire_simplifie
        }

        if (data && data.data && data.data.formulaire_detaillé) {
          this.data = data.data.formulaire_detaillé



        } else {
          var lst: Trancons[] = []
          for (let r = 0; r < this.nbr; r++) {
            //this.photo.push()//(src+r+'.png')
            tr = new Trancons();
            tr.numero = this.nbr - r
            tr.diagonales = new Dimension()
            tr.membrures = new Dimension()
            tr.traverses = new Dimension()
            lst[r] = tr

          }
          this.data.trancons = lst

        }
        //console.log(this.data.trancons,"lst")

      })





    });    
  }

  testgps(){
    this.gps=false
  this.run=false 
  this.selectmethode=false
  this.methode=false
  this.s=false
 }




}
