import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from './service/authentication.service';

let currentUser = JSON.parse(localStorage.getItem('currentUser')+'');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Visite technique';
 
  message: any
  isLoggedIn:any

  constructor(private authService: AuthenticationService, private router: Router
    ) {

if (!this.authService.isLogged()) {
  this.isLoggedIn= false;

 this.router.navigate(['/login'])
} else {
  this.isLoggedIn= true
  
 // this.verifRole()
}


   }

   ngOnInit(): void {
    if (!this.authService.isLogged()) {
      this.isLoggedIn= false;
      localStorage.clear();
     this.router.navigate(['/login'])
    } else {
      this.isLoggedIn= true
      
     // this.verifRole()
    }

    if(currentUser.authdata==''){
      this.isLoggedIn= false;
      localStorage.clear();
      this.router.navigate(['/login'])
    }
   }

 
}
