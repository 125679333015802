<div class="card" style="margin: 5px">
    <div class="card-header navsize">
        <button class="col-md-4 col-sm-3 btn text label gps" style="
        background-color: white;
        color: white;
        border: none;
        width: max-content;
        float: left;
        box-shadow: inset 0 0 0 2px #04c4db;
        cursor: default;
      ">
            <span class="material-icons" style="
          vertical-align: sub !important;
          margin-right: 5px !important;
          color: #04c4db;
        ">
                account_circle
            </span>
            <a style="font-family: system-ui; font-weight: bold; color: #04c4db">{{
                username
                }}</a>
        </button>

        <button class="col-md-4 col-sm-3 btn text label gps" style="
        background-color: #04c4db;
        color: white;
        border: none;
        width: max-content;
        float: right;
      ">
            <span class="material-icons" style="vertical-align: sub !important; margin-right: 5px !important">
                logout
            </span>

            <a style="font-family: system-ui; font-weight: bold" href="/login">Déconnexion</a>
        </button>
    </div>
    <!--    TYPE DE VISITE-->
    <div class="card-body">
        <!-- Code site + longitude + latitude + hauteur -->
        <div class="container">
            <div class="row">
              
                <div class="input-group mb-3">
                  <span class="input-group-text">Code site :</span>
                  <input [readonly]="gpsrequired" 
                  type="text" class="form-control" matInput [matAutocomplete]="auto" [formControl]="stateCtrl" [(ngModel)]="code"
                            (change)="testgps()" (ngModelChange)="changecode()" />
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                                <span>{{ state }}</span>
                            </mat-option>
                        </mat-autocomplete>
                </div>
                 <div class="input-group mb-3">
                  <span class="input-group-text">Nom Site :</span>
                  <input [readonly]="gpsrequired" 
                  type="text" class="form-control" matInput [matAutocomplete]="auto2" [formControl]="stateCtrl2" [(ngModel)]="nom"
                            (change)="testgps()" (ngModelChange)="changenom()" />
                        <mat-autocomplete #auto2="matAutocomplete">
                            <mat-option *ngFor="let state of filteredStates2 | async" [value]="state">
                                <span>{{ state }}</span>
                            </mat-option>
                        </mat-autocomplete>
                </div>
                <div class="input-group mb-3 ">
                    <button *ngIf="gpsrequired == 'false'"  class="btn btn-primary"
                    (click)="gpscodesite()"><i class="material-icons">location_on</i>
                    Détecter
                    </button>
                    <button *ngIf="gpsrequired == 'true'"  class="btn btn-primary"
                        (click)="gpscodesite()"><i class="material-icons">location_on</i>
                        Détecter
                    </button>
                  </div>
                  <div class="input-group mb-3">
                    <button *ngIf="gpsrequired == 'false'" class="btn btn-primary"
                    (click)="getNbr()"><i class="material-icons">done</i>
                    Valider
                   </button>
                   <button *ngIf="gpsrequired == 'true'"  class="btn btn-primary" 
                        (click)="validergps()"><i class="material-icons">done</i>
                        Valider
                   </button>
                  </div>
   
            </div>
           
          </div>
        
        
        <hr *ngIf="(run || gps) && s" style="margin-top: 0px" />
        <div class="bigscreen" *ngIf="s">
            <div *ngIf="run || gps">
                <label>
                    <p>Nombre de tronçons</p>
                    <input [readonly]="run" matInput type="text" class="text text2" name="longitude"
                        [(ngModel)]="nbr" />
                </label>
                <br *ngIf="!gps" />
                <label>
                    <p>Adresse</p>
                    <input [readonly]="run" matInput type="text" class="text text2" name="longitude"
                        [(ngModel)]="adress" />
                </label>
                <br *ngIf="!gps" />
                <label>
                    <p>Département</p>
                    <input [readonly]="run" matInput type="text" class="text text2" name="longitude"
                        [(ngModel)]="department" />
                </label>
            </div>

            <div *ngIf="gps">
                <label>
                    <p>Latitude</p>
                    <input [readonly]="run" matInput type="text" class="text text2" name="longitude"
                        [(ngModel)]="latitude" />
                </label>
                <label>
                    <p>Longitude</p>
                    <input [readonly]="run" matInput type="text" class="text text2" name="longitude"
                        [(ngModel)]="longitude" />
                </label>
                <label>
                    <p>Hauteur</p>
                    <input [readonly]="run" matInput type="text" class="text text2" name="longitude"
                        [(ngModel)]="hauteur" />
                </label>
            </div>
        </div>
        <br />
        <hr *ngIf="selectmethode" style="margin-top: 0px" />
        <div *ngIf="selectmethode" class="radio-inputs">
            <label class="radio">
                <input [(ngModel)]="methode" (change)="onChangeMethode($event)" type="radio" name="radio" value="simple"
                    checked />
                <span class="name">Visite simplifiée (relevé d'équipements)</span>
            </label>
            <label class="radio">
                <input [(ngModel)]="methode" (change)="onChangeMethode($event)" type="radio" name="radio"
                    value="detail" />
                <span class="name">Visite détaillée (relevé de pylône)</span>
            </label>
        </div>
        <br *ngIf="methode" />
        <hr *ngIf="methode" style="margin-top: 0px" />

        <div class="bodymain" *ngIf="s && methode == 'detail'">
            <br />
            <h1 *ngIf="tabs == -1" class="infos" style="
          margin-left: auto;
          margin-right: auto;
          width: max-content;
          color: #04c4db;
          font-family: poppines;
          font-weight: bold;
        ">
                Visite détaillée (relevé de pylône)
            </h1>
            <h1 *ngIf="tabs >= 0" class="infos" style="
          margin-left: auto;
          margin-right: auto;
          width: max-content;
          color: #04c4db;
          font-family: poppines;
          font-weight: bold;
        ">
                Informations sur le tronçon {{ pp }}
            </h1>
            <h1 *ngIf="tabs == -2" class="infos" style="
          margin-left: auto;
          margin-right: auto;
          width: max-content;
          color: #04c4db;
          font-family: poppines;
          font-weight: bold;
        ">
                Informations sur la fondation
            </h1>

            <div class="card" *ngIf="tabs == -1">
                <div style="
            display: grid;
            padding: 10px;
            cursor: pointer;
            width: fit-content;
            margin: auto;
          ">
                    <span class="material-icons" style="font-size: xx-large; text-align: center">
                        photo_camera</span>
                    <button (click)="openPhoto()" class="btn btn-red">
                        <label style="font-size: small; cursor: pointer">
                            Prendre une photo <br />
                            générale du site</label>
                    </button>
                </div>
                <div class="normal-text col-md-6" style="font-family: poppines bold">
                    &nbsp;&nbsp;
                    <input matInput type="text" class="text" name="msg" [(ngModel)]="msg"
                        style="border: none; margin-bottom: 20px; background: white" disabled="true" />
                </div>
                <a hidden="true" (click)="infos()"
                    style="font-size: large; font-weight: bold; text-align: center">Fondation <mat-icon>
                        horizontal_split</mat-icon></a>

                <br />

                <div *ngFor="let row of data.trancons.reverse(); let i = index">
                    <div style="margin-right: auto; margin-left: auto; width: fit-content">
                        <div style="display: inline-flex; overflow: hidden; position: relative" #container>
                            <a *ngIf="row?.valide" style="font-size: medium; font-weight: bold; color: green"
                                (click)="onchangeModel(data.trancons.length - i, $event)"
                                title="Cliquer pour renseigner les informations sur ce tronçon">T {{
                                data.trancons.length - i }}</a>
                            <a *ngIf="!row?.valide" style="font-size: medium; font-weight: bold; color: black"
                                (click)="onchangeModel(data.trancons.length - i, $event)"
                                title="Cliquer pour renseigner les informations sur ce tronçon">T
                                {{ data.trancons.length - i }}
                            </a>
                            <mat-icon *ngIf="row.valide"
                                class="material-icons color_green">check_circle_outline</mat-icon>
                            <mat-icon *ngIf="!row.valide" class="material-icons" color="warn">cancel</mat-icon>
                        </div>

                        <img src="{{ image[i] }}" width="{{ style[i] }}" class="{{ class[i] }}"
                            (click)="onchangeModel(data.trancons.length - i, $event)" id="style"
                            style="cursor: pointer; position: relative"
                            title="Cliquer pour renseigner les informations sur ce tronçon" />
                    </div>
                </div>

                <div>
                    <div style="margin-right: auto; margin-left: auto; width: fit-content">
                        <div style="display: inline-flex">
                            <a (click)="openStepper(-2)" style="font-size: medium; font-weight: bold">FD
                            </a>
                            <mat-icon 
                                class="material-icons color_info">info</mat-icon>
                        </div>
                        <img *ngIf="codesite != 'FR-MQ-1056'" src="../../assets/img/base.png" width="210"
                            (click)="openStepper(-2)" style="cursor: pointer"
                            title="Cliquer pour renseigner les informations sur la fondation" />
                        <img *ngIf="codesite == 'FR-MQ-1056'" src="../../assets/img/base.png" width="130"
                            (click)="openStepper(-2)" style="cursor: pointer"
                            title="Cliquer pour renseigner les informations sur la fondation" />
                    </div>
                </div>
                <br />
                <div *ngIf="allvalid(data.trancons)" class="row"
                    style="margin-right: auto; margin-left: auto; width: fit-content">
                    <button mat-raised-button class="btn btn-danger"
                        style="background-color: #04c4db; color: white; border: none" (click)="valider()">
                        Envoyer la visite détaillée pour validation
                    </button>
                </div>
                <br />
                <br />
            </div>
            <div class="card" *ngIf="tabs > -1" style="height: 1500px">
                <a style="
            font-weight: bold;
            display: flex;
            cursor: pointer;

            color: #00658d;
          " (click)="pylone()">
                    <mat-icon>arrow_back</mat-icon> page précédente
                </a>
                <div class="das" *ngFor="
            let row of data.trancons
              | paginate : { itemsPerPage: 1, currentPage: pp };
            let i = index
          ">
                    <div class="pagination">
                        <pagination-controls hidden="true" (pageChange)="pp = $event"
                            (click)="onchangeModel(pp, $event)" previousLabel nextLabel></pagination-controls>
                    </div>
                    <table class="table" *ngIf="vue">
                        <tbody>
                            <tr>
                                <td style="height: 1300px">
                                    <table *ngIf="codesite != 'FR-MQ-1056'">
                                        <tr>
                                            <td>
                                                <table>
                                                    <tr style="height: 0">
                                                        <td (click)="openPhotoTr(pp)" style="
                                cursor: pointer;
                                cursor: pointer;
                                margin: auto;
                              ">
                                                            <div style="
                                  display: grid;
                                  width: fit-content;
                                  margin: auto;
                                ">
                                                                <span class="material-icons" style="
                                    font-size: xx-large;
                                    text-align: center;
                                  ">
                                                                    photo_camera</span>
                                                                <button class="btn btn-red"
                                                                    style="display: inline-flex">
                                                                    <label style="
                                      font-size: small;
                                      text-align: center;
                                      margin-right: auto;
                                      margin-left: auto;
                                      cursor: pointer;
                                    ">
                                                                        Prendre une photo
                                                                        <br />du tronçon</label>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <br />
                                                        <br />

                                                        <td>
                                                            <div class="col photo">
                                                                <img src="../../assets/img/P_1.png"
                                                                    style="height: 500px; float: Right" id="tower"
                                                                    class="myImg" alt="Trolltunga" />
                                                            </div>
                                                        </td>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <td>
                                                            <div (click)="openPhotoBr(pp)" style="
                                  display: grid;
                                  cursor: pointer;
                                  width: fit-content;
                                  margin: auto;
                                ">
                                                                <span class="material-icons" style="
                                    font-size: xx-large;
                                    text-align: center;
                                  ">
                                                                    photo_camera</span>
                                                                <button class="btn btn-red"
                                                                    style="display: inline-flex">
                                                                    <label style="
                                      font-size: small;
                                      text-align: center;
                                      margin-right: auto;
                                      margin-left: auto;
                                      cursor: pointer;
                                    ">
                                                                        Prendre une photo
                                                                        <br />
                                                                        de bride</label>
                                                                </button>
                                                            </div>

                                                            <br />
                                                            <h2 *ngIf="tabs >= 0 && pp == 1" class style="
                                  margin-left: auto;
                                  margin-right: auto;
                                  width: max-content;
                                  color: #04c4db;
                                  font-family: poppines;
                                  font-weight: bold;
                                  width: 250px;
                                  word-wrap: break-word;
                                  text-align: center;
                                ">
                                                                Ancrage : Assemblage entre le pylône et la
                                                                fondation
                                                            </h2>
                                                            <h2 *ngIf="tabs >= 0 && pp != 1" class style="
                                  margin-left: auto;
                                  margin-right: auto;
                                  width: max-content;
                                  color: #04c4db;
                                  font-family: poppines;
                                  font-weight: bold;
                                  width: 200px;
                                  word-wrap: break-word;
                                  text-align: center;
                                ">
                                                                Assemblage entre les tronçons {{ pp }} et
                                                                {{ pp - 1 }}
                                                            </h2>
                                                            <br />
                                                        </td>

                                                        <td>
                                                            <div class="photo">
                                                                <img src="../../assets/img/bride3.png" id="tower"
                                                                    class="myImg1" alt="Trolltunga" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                        </td>
                                                        <td>
                                                            <div style="margin-top: -24px">
                                                                <div style="display: grid; width: 100%">
                                                                    Commentaire :<textarea
                                                                        style="width: auto; height: 100px" matInput
                                                                        type="text" class="text tronc"
                                                                        name="Commentaire"
                                                                        [(ngModel)]="data.commentaire">
                                  </textarea>
                                                                </div>
                                                            </div>

                                                            <br />
                                                            <button mat-raised-button style="
                                  background-color: #04c4db;
                                  color: white;
                                  border: none;
                                  width: 100%;
                                  position: relative;
                                " (click)="validerTr(pp - 1)">
                                                                Valider le tronçon
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <div class="memb">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openTable('membrures', pp)">
                                                                Membrures
                                                            </button></label>
                                                    </div>
                                                    <div class="diag">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openTable('diagonales', pp)">
                                                                Diagonales
                                                            </button></label>
                                                    </div>
                                                    <div class="trav">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openTable('traverses', pp)">
                                                                Traverses
                                                            </button></label>
                                                    </div>
                                                    <div class="zinf">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('zinf', pp)">
                                                                Zinf
                                                            </button></label>
                                                    </div>
                                                    <div class="binf">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('binf', pp)">
                                                                Binf
                                                            </button></label>
                                                    </div>
                                                    <div class="zsup">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('zsup', pp)">
                                                                Zsup
                                                            </button></label>
                                                    </div>
                                                    <div class="bsup">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('bsup', pp)">
                                                                Bsup
                                                            </button></label>
                                                    </div>
                                                </table>
                                            </td>
                                        </tr>

                                        <div class="dtiges">
                                            <label *ngIf="pp == 1"><button mat-stroked-button color="warn"
                                                    class="btn-bride" (click)="openAttr('dtiges', pp)">
                                                    &nbsp;&nbsp;Diamètre tige&nbsp;&nbsp;
                                                </button></label>
                                            <label *ngIf="pp > 1"><button mat-stroked-button color="warn"
                                                    class="btn-bride" (click)="openAttr('dtiges', pp)">
                                                    Diamètre boulon
                                                </button></label>
                                        </div>
                                        <div class="dbride">
                                            <label><button mat-stroked-button color="warn" class="btn-bride"
                                                    (click)="openAttr('dbride', pp)">
                                                    Diamètre bride
                                                </button></label>
                                        </div>
                                        <div class="drep">
                                            <label><button mat-stroked-button color="warn" class="btn-bride"
                                                    (click)="openAttr('drepartition', pp)">
                                                    Diamètre répartition
                                                </button></label>
                                        </div>
                                        <div class="ebride">
                                            <label><button mat-stroked-button color="warn" class="btn-bride"
                                                    (click)="openAttr('ebride', pp)">
                                                    Epaisseur bride
                                                </button></label>
                                        </div>
                                        <!-- additional buttons -->
                                        <div class="dtiges1">
                                            <label *ngIf="pp > 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_boulon', pp)">
                                                    Matériau boulons
                                                </button></label>
                                            <label *ngIf="pp == 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_tiges', pp)">
                                                    Matériau tiges
                                                </button></label>
                                        </div>
                                        <div class="dtiges1">
                                            <label *ngIf="pp > 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_bride', pp)">
                                                    Matériau bride
                                                </button></label>
                                            <label *ngIf="pp == 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_plaque', pp)">
                                                    Matériau plaque
                                                </button></label>
                                        </div>
                                        <div class="dtiges1">
                                            <label *ngIf="pp > 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('nb_boulons', pp)">
                                                    Nombre de boulons
                                                </button></label>
                                            <label *ngIf="pp == 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('nb_tiges', pp)">
                                                    Nombre de tiges
                                                </button></label>
                                        </div>
                                    </table>

                                    <!-- MONOTUBE-->

                                    <table *ngIf="codesite == 'FR-MQ-1056'">
                                        <tr>
                                            <td>
                                                <table>
                                                    <tr style="height: 0">
                                                        <td (click)="openPhotoTr(pp)" style="
                                cursor: pointer;
                                cursor: pointer;
                                margin: auto;
                              ">
                                                            <div style="
                                  display: grid;
                                  width: fit-content;
                                  margin: auto;
                                ">
                                                                <span class="material-icons" style="
                                    font-size: xx-large;
                                    text-align: center;
                                  ">
                                                                    photo_camera</span>
                                                                <button class="btn btn-red"
                                                                    style="display: inline-flex">
                                                                    <label style="
                                      font-size: small;
                                      text-align: center;
                                      margin-right: auto;
                                      margin-left: auto;
                                      cursor: pointer;
                                    ">
                                                                        Prendre une photo
                                                                        <br />du tronçon</label>
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <br />
                                                        <br />

                                                        <td>
                                                            <div class="col photo">
                                                                <img src="../../assets/img/mono3.png"
                                                                    style="height: 500px; float: Right" id="tower"
                                                                    class="myImg" alt="Trolltunga" />
                                                            </div>
                                                        </td>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <td>
                                                            <div (click)="openPhotoBr(pp)" style="
                                  display: grid;
                                  cursor: pointer;
                                  width: fit-content;
                                  margin: auto;
                                ">
                                                                <span class="material-icons" style="
                                    font-size: xx-large;
                                    text-align: center;
                                  ">
                                                                    photo_camera</span>
                                                                <button class="btn btn-red"
                                                                    style="display: inline-flex">
                                                                    <label style="
                                      font-size: small;
                                      text-align: center;
                                      margin-right: auto;
                                      margin-left: auto;
                                      cursor: pointer;
                                    ">
                                                                        Prendre une photo
                                                                        <br />
                                                                        de bride</label>
                                                                </button>
                                                            </div>

                                                            <br />
                                                            <h2 *ngIf="tabs >= 0 && pp == 1" class style="
                                  margin-left: auto;
                                  margin-right: auto;
                                  width: max-content;
                                  color: #04c4db;
                                  font-family: poppines;
                                  font-weight: bold;
                                  width: 250px;
                                  word-wrap: break-word;
                                  text-align: center;
                                ">
                                                                Ancrage : Assemblage entre le pylône et la
                                                                fondation
                                                            </h2>
                                                            <h2 *ngIf="tabs >= 0 && pp != 1" class style="
                                  margin-left: auto;
                                  margin-right: auto;
                                  width: max-content;
                                  color: #04c4db;
                                  font-family: poppines;
                                  font-weight: bold;
                                  width: 200px;
                                  word-wrap: break-word;
                                  text-align: center;
                                ">
                                                                Assemblage entre les tronçons {{ pp }} et
                                                                {{ pp - 1 }}
                                                            </h2>
                                                            <br />
                                                        </td>

                                                        <td>
                                                            <div class="photo">
                                                                <img src="../../assets/img/bride3.png" id="tower"
                                                                    class="myImg1" alt="Trolltunga" />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                        </td>
                                                        <td>
                                                            <div style="margin-top: -24px">
                                                                <div style="display: grid; width: 100%">
                                                                    Commentaire :<textarea
                                                                        style="width: auto; height: 100px" matInput
                                                                        type="text" class="text tronc"
                                                                        name="Commentaire"
                                                                        [(ngModel)]="data.commentaire">
                                  </textarea>
                                                                </div>
                                                            </div>

                                                            <br />
                                                            <button mat-raised-button style="
                                  background-color: #04c4db;
                                  color: white;
                                  border: none;
                                  width: 100%;
                                  position: relative;
                                " (click)="validerTr(pp - 1)">
                                                                Valider le tronçon
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <div class="mono-memb">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openTable('membrures', pp)">
                                                                Membrures
                                                            </button></label>
                                                    </div>

                                                    <div class="mono-zinf">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('zinf', pp)">
                                                                Zinf
                                                            </button></label>
                                                    </div>
                                                    <div class="mono-diam-inf">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('binf', pp)">
                                                                Diamètre inf
                                                            </button></label>
                                                    </div>
                                                    <div class="mono-zsup">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('zsup', pp)">
                                                                Zsup
                                                            </button></label>
                                                    </div>
                                                    <div class="mono-diam-sup">
                                                        <label><button mat-stroked-button color="white"
                                                                (click)="openAttr('bsup', pp)">
                                                                Diamètre sup
                                                            </button></label>
                                                    </div>
                                                </table>
                                            </td>
                                        </tr>

                                        <div class="mono-diam-boulon">
                                            <label *ngIf="pp == 1"><button mat-stroked-button color="warn"
                                                    class="btn-bride" (click)="openAttr('dtiges', pp)">
                                                    &nbsp;&nbsp;Diamètre tige&nbsp;&nbsp;
                                                </button></label>
                                            <label *ngIf="pp > 1"><button mat-stroked-button color="warn"
                                                    class="btn-bride" (click)="openAttr('dtiges', pp)">
                                                    Diamètre boulon
                                                </button></label>
                                        </div>
                                        <div class="mono-diam-bride">
                                            <label><button mat-stroked-button color="warn" class="btn-bride"
                                                    (click)="openAttr('dbride', pp)">
                                                    Diamètre bride
                                                </button></label>
                                        </div>
                                        <div class="mono-diam-rep">
                                            <label><button mat-stroked-button color="warn" class="btn-bride"
                                                    (click)="openAttr('drepartition', pp)">
                                                    Diamètre répartition
                                                </button></label>
                                        </div>
                                        <div class="mono-ep-bride">
                                            <label><button mat-stroked-button color="warn" class="btn-bride"
                                                    (click)="openAttr('ebride', pp)">
                                                    Epaisseur bride
                                                </button></label>
                                        </div>
                                        <!-- additional buttons -->
                                        <div class="mono-mat-boulon">
                                            <label *ngIf="pp > 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_boulon', pp)">
                                                    Matériau boulons
                                                </button></label>
                                            <label *ngIf="pp == 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_tiges', pp)">
                                                    Matériau tiges
                                                </button></label>
                                        </div>
                                        <div class="mono-mat-boulon">
                                            <label *ngIf="pp > 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_bride', pp)">
                                                    Matériau bride
                                                </button></label>
                                            <label *ngIf="pp == 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('mat_plaque', pp)">
                                                    Matériau plaque
                                                </button></label>
                                        </div>
                                        <div class="mono-mat-boulon">
                                            <label *ngIf="pp > 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('nb_boulons', pp)">
                                                    Nombre de boulons
                                                </button></label>
                                            <label *ngIf="pp == 1"><button mat-stroked-button class="btn-bride"
                                                    color="warn" (click)="openAttr('nb_tiges', pp)">
                                                    Nombre de tiges
                                                </button></label>
                                        </div>
                                    </table>

                                    <!-- END MONOTUBE -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="card" *ngIf="tabs == -2 && methode == 'detail'">
            <div style="display: grid">
                <a style="
            font-weight: bold;
            display: flex;
            margin: 20px;
            cursor: pointer;
            color: #00658d;
          " (click)="pylone()">
                    <mat-icon>arrow_back</mat-icon> page précédente
                </a>
            </div>

            <div (click)="openPhotoBase()" style="display: grid; cursor: pointer; width: fit-content; margin: auto">
                <span class="material-icons" style="font-size: xx-large; text-align: center">
                    photo_camera</span>
                <button class="btn btn-red" style="display: inline-flex">
                    <label style="
              font-size: small;
              text-align: center;
              margin-right: auto;
              margin-left: auto;
              cursor: pointer;
            ">
                        Prendre une photo <br />
                        de la fondation</label>
                </button>
            </div>
            <br />
            <table style="margin-left: auto; margin-right: auto; width: 50%">
                <tbody>
                    <tr>
                        <th><img src="../../assets/img/ing.png" class="imgfonda" /></th>
                    </tr>
                </tbody>
            </table>
            <div style="padding: 7px">
                <div class="row" style="margin: auto">
                    <div class="col">
                        <label for="hms" class="labelfond">
                            <div class="important">*</div>
                            Hms
                        </label>
                        <br />
                        <input [disabled]="this.hms" matInput type="number" class="text tronc" name="hms"
                            [(ngModel)]="data.hms" /><label style="margin-right: 30px; vertical-align: super">
                            (m)</label>
                        <button *ngIf="!this.hms" class="btn-x" (click)="this.hms = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.hms" class="btn-y" (click)="this.hms = false">
                            <i class="material-icons">edit</i>
                        </button>
                        <div *ngIf="(hms && data.hms == '') || (hms && data.hms == null)"
                            style="color: red; display: flex">
                            *Champ obligatoires
                        </div>
                    </div>
                    <div class="col">
                        <label for="lf" class="labelfond">
                            <div class="important">*</div>
                            Lf
                        </label><br /><input [disabled]="this.lf" matInput type="number" class="text tronc" name="lf"
                            [(ngModel)]="data.lf" />
                        <label style="margin-right: 30px; vertical-align: super">
                            (m)</label>
                        <button *ngIf="!this.lf" class="btn-x" (click)="this.lf = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.lf" class="btn-y" (click)="this.lf = false">
                            <i class="material-icons">edit</i>
                        </button>
                        <div *ngIf="(lf && data.lf == '') || (lf && data.lf == null)" style="color: red; display: flex">
                            *Champ obligatoires
                        </div>
                    </div>
                </div>
                <br />
                <div class="row" style="margin: auto">
                    <div class="col">
                        <label for="hf" class="labelfond">
                            <div class="important">*</div>
                            Hf
                        </label><br />
                        <input [disabled]="this.hf" matInput type="number" class="text tronc" name="hf"
                            [(ngModel)]="data.hf" /><label style="margin-right: 30px; vertical-align: super">
                            (m)</label>
                        <button *ngIf="!this.hf" class="btn-x" (click)="this.hf = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.hf" class="btn-y" (click)="this.hf = false">
                            <i class="material-icons">edit</i>
                        </button>
                        <div *ngIf="(hf && data.hf == '') || (hf && data.hf == null)" style="color: red; display: flex">
                            *Champ obligatoires
                        </div>
                    </div>
                    <div class="col">
                        <label for="h" class="labelfond">
                            <div class="important">*</div>
                            H
                        </label><br />
                        <input [disabled]="this.h" matInput type="number" class="text tronc" name="h"
                            [(ngModel)]="data.h" /><label style="margin-right: 30px; vertical-align: super">
                            (m)</label>
                        <button *ngIf="!this.h" class="btn-x" (click)="this.h = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.h" class="btn-y" (click)="this.h = false">
                            <i class="material-icons">edit</i>
                        </button>
                        <div *ngIf="(h && data.h == '') || (h && data.h == null)" style="color: red; display: flex">
                            *Champ obligatoires
                        </div>
                    </div>
                </div>
                <br />
                <div class="row" style="margin: auto">
                    <div class="col">
                        <label for="a" class="labelfond">
                            <div class="important">*</div>
                            A
                        </label><br />
                        <input [disabled]="this.a" matInput type="number" class="number" class="text tronc" name="a"
                            [(ngModel)]="data.a" />
                        <label style="margin-right: 30px; vertical-align: super">
                            (m)</label>
                        <button *ngIf="!this.a" class="btn-x" (click)="this.a = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.a" class="btn-y" (click)="this.a = false">
                            <i class="material-icons">edit</i>
                        </button>
                        <div *ngIf="(a && data.a == undefined) || (a && data.a == null)"
                            style="color: red; display: flex">
                            *Champ obligatoires
                        </div>
                    </div>
                    <div class="col">
                        <label for="b" class="labelfond">
                            <div class="important">*</div>
                            B
                        </label><br />
                        <input [disabled]="this.b" matInput type="number" class="number" class="text tronc" name="b"
                            [(ngModel)]="data.b" /><label style="margin-right: 30px; vertical-align: super">
                            (m)</label>
                        <button *ngIf="!this.b" class="btn-x" (click)="this.b = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.b" class="btn-y" (click)="this.b = false">
                            <i class="material-icons">edit</i>
                        </button>
                        <div *ngIf="(b && data.b == undefined) || (b && data.b == null)"
                            style="color: red; display: flex">
                            *Champ obligatoires
                        </div>
                    </div>
                </div>
                <br />
                <div class="row" style="margin: auto">
                    <div class="col">
                        <label class="labelfond">Contrainte limite du sol à l'ELU</label><br />
                        <input [disabled]="this.elu" matInput type="number" class="text" name="elu"
                            [(ngModel)]="data.elu" /><label style="margin-right: 30px; vertical-align: super">
                            (KPa)</label>
                        <button *ngIf="!this.elu" class="btn-x" (click)="this.elu = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.elu" class="btn-y" (click)="this.elu = false">
                            <i class="material-icons">edit</i>
                        </button>
                    </div>
                    <div class="col">
                        <label class="labelfond">Contrainte limite du sol à l'ELS</label><br />
                        <input [disabled]="els" matInput type="number" class="text" name="els"
                            [(ngModel)]="data.els" /><label style="margin-right: 30px; vertical-align: super">
                            (KPa)</label>
                        <button *ngIf="!this.els" class="btn-x" (click)="this.els = true">
                            <i class="material-icons">done</i>
                        </button>
                        <button *ngIf="this.els" class="btn-y" (click)="this.els = false">
                            <i class="material-icons">edit</i>
                        </button>
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col">
                        <label class="labelfond">Commentaire :</label><textarea matInput class="text classtd"
                            name="commentaire" [(ngModel)]="data.commentaire"
                            style="width: -webkit-fill-available; height: 70px"></textarea>
                    </div>
                </div>
                <br />
                <div class="row" style="display: flex; align-items: center; justify-content: center">
                    <button mat-raised-button class="col-md-4 btn btn-danger center" style="
              background-color: #04c4db;
              color: white;
              border: none;
              width: fit-content;
            " (click)="validerBase()">
                        Valider la fondation
                    </button>
                </div>
                <br />
            </div>
        </div>
    </div>
    <div *ngIf="s && methode == 'simple'">
        <div>
            <button style="
          background-color: #00658d;
          background-color: #04c4db;
          height: 40px;
          width: 150px;
          margin-bottom: 20px;
          border: none;
          color: #fafafa;
          font-family: poppines bold;
          font-size: larger;
          font-weight: bold;
        " (click)="openSimpleData()">
                Ajouter
            </button>
            <table class="datatables container">
                <tr style="display: revert; text-align: center">
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Type
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Dimension
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        az
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Constructeur
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Hma
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Nb coax
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Type coax
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Commentaire
                    </th>
                    <th style="
              color: #00658d;
              font-weight: 600;
              border-right: solid;
              border-bottom: solid;
            ">
                        Actions
                    </th>
                </tr>

                <tr *ngFor="let c of sdatas" style="display: revert; text-align: center">
                    <td>{{ c.Type }}</td>
                    <td>{{ c.Diametre }}</td>
                    <td>{{ c.az }}</td>
                    <td>{{ c.constructeur }}</td>
                    <td>{{ c.hma }}</td>
                    <td>{{ c.nb_coax }}</td>
                    <td>{{ c.type_coax }}</td>
                    <td>{{ c.commentaire }}</td>
                    <td>
                        <button class="btn btn-blue" (click)="openDialogEdit(c)">
                            <span style="color: #04c4db" class="material-icons"> edit </span>
                        </button>
                    </td>
                </tr>
            </table>
            <!--MOBILE VIEW-->
            <br />
        </div>
        <div class="mob-table">
            <table style="width: 100%" *ngFor="let c of sdatas; let i = index">
                <hr *ngIf="i > 0" />
                <br />
                <tr>
                    <th>Numéro</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ i + 1 }}</td>
                </tr>
                <tr>
                    <th>Type</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.Type }}</td>
                </tr>
                <tr>
                    <th>Dimension</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.Diametre }}</td>
                </tr>
                <tr>
                    <th>az</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.az }}</td>
                </tr>
                <tr>
                    <th>Constructeur</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.constructeur }}</td>
                </tr>
                <tr>
                    <th>Hma</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.hma }}</td>
                </tr>
                <tr>
                    <th>Nb coax</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.nb_coax }}</td>
                </tr>
                <tr>
                    <th>Type coax</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.type_coax }}</td>
                </tr>
                <tr>
                    <th>Commentaire</th>
                    <div style="border-left: 1px solid #000; height: 30px"></div>
                    <td>{{ c.commentaire }}</td>
                </tr>
                <br />
                <div class="col-sm">
                    <button mat-stroked-button style="background: #04c4db; color: white" (click)="openDialogEdit(c)">
                        Modifier
                    </button>
                </div>
                <br />
            </table>
        </div>

        <div class="row" style="display: flex; align-items: center; justify-content: center">
            <button mat-raised-button class="col-md-4 btn btn-danger center" style="
          background-color: #04c4db;
          color: white;
          border: none;
          width: 215px;
        " (click)="valider()">
                Valider
            </button>
        </div>
        <br />
    </div>

    <div id="myModal" class="modal">
        <span class="close">&times;</span>
        <img class="modal-content" id="tower" />
        <div id="caption"></div>
    </div>
</div>
<script type="text/javascript" src="https://code.jquery.com/jquery-3.3.1.js"></script>

<script type="text/javascript">
    $(document).ready(function () {
        var modal = $("#myModal");
        var span = $(".close");
        var modalImg = $("#img01");
        var captionText = $("#caption");

        var img = $(".myImg");

        img.click(function () {
            modal.css("display", "block");
            modalImg.attr("src", this.src);
            captionText.html(this.alt);
        });

        span.click(function () {
            modal.css("display", "none");
        });
    });
</script>

<ng-template #validation>
    <div class="validation">
        <label style="margin-right: 30px">
            <p>Nombre de tronçons</p>
            <input matInput type="number" class="text text2" name="longitude" [(ngModel)]="newnbr" />
        </label>
        <button mat-raised-button mat-dialog-close class="col-md-4 btn text label" style="
        background-color: #04c4db;
        font-family: poppines;
        color: white;
        border: none;
        width: fit-content;
      ">
            <span style="font-size: smaller; margin-left: -5px">Valider</span>
        </button>
    </div>
</ng-template>