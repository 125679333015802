import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { User } from '../class/user';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private service: AuthenticationService,private router: Router,) { }

  username: string='';
  password: string='';
  passwordVisible: boolean = false;
  ngOnInit(): void {
  }

  doLogin() {
   
   
    let resp = this.service.login(this.username, this.password);
    resp.subscribe((data:any) => {
      console.log('login data',data)
      localStorage.setItem('username',this.username)

     if(data){
      var user=new User();
      console.log(data)
     user.authdata = window.btoa(this.username + ':' +this.password);
     localStorage.setItem('currentUser', JSON.stringify(user));
     localStorage.setItem('logged', true+'');
     localStorage.setItem('sites', JSON.stringify(data));
     localStorage.setItem('gpsrequired',data.gps_required)
      console.log('logged',user)
     this.router.navigate(["/site"])
     }
    },
        error => {
    Swal.fire('Erreur',"Le nom d'utilisateur ou le mot de passe est incorrect ",'error')
  }
    );
  }
  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }

}
