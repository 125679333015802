import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  VERSION,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Data } from '../class/data';
import { Dimension } from '../class/dimension';
import { Trancons } from '../class/trancons';
import { SiteService } from '../service/site.service';
import { SimpleDataComponent } from '../simple-data/simple-data.component';
import { saveAs } from 'file-saver';
import { map, Observable, startWith } from 'rxjs';
import Swal from 'sweetalert2';
import { SimpleAttComponent } from '../simple-att/simple-att.component';
import { PhotoService } from '../service/photo.service';
import { UserPhoto } from '../class/user-photo';
import { PhotoComponent } from '../photo/photo.component';
import { AuthenticationService } from '../service/authentication.service';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
})
export class SiteComponent implements OnInit {
  image: any[] = [];
  style: any[] = [];
  class: any[] = [];
  pp: number = 1;
  run: boolean = false;
  latitude: any;
  longitude: any;
  collection!: any[];
  materieu!: string;
  // p:any
  codesite: string = '';
  p2: any = 1;
  nom: string = '';
  methode: any;
  sdatas: any[] = [];
  images: UserPhoto[] = [];
  mattiges = ['4.6', '4.8', '5.6', '5.8', '6.8', '8.8', '10.9'];
  matplaque = ['S355', 'S235'];
  vueMem: boolean = false;
  showMethode: any = true;
  hauteur: any;
  @Input() id!: string;
  @Input() maxSize!: number;
  @Output() pageChange!: EventEmitter<number>;
  @Output() pageBoundsCorrection!: EventEmitter<number>;
  @ViewChild('validation') validation: any;

  myControl = new FormControl('');
  stateCtrl!: FormControl;
  stateCtrl2!: FormControl;

  filteredStates!: Observable<any[]>;
  filteredStates2!: Observable<any[]>;
  states: string[] = this.authService.getSites().codes_site;
  states2: string[] = this.authService.getSites().names_site;

  adress!: any;
  department!: any;
  hms: boolean = false;
  lf: boolean = false;
  hf: boolean = false;
  h: boolean = false;
  a: boolean = false;
  b: boolean = false;
  elu: boolean = false;
  els: boolean = false;
  selectmethode = false;
  validated: boolean = false;
  constructor(
    public photoService: PhotoService,
    private siteService: SiteService,
    public dialog: MatDialog,
    public authService: AuthenticationService
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.methode == 'detail' && this.validated == false) {
      $event.returnValue = true;
    }
  }

  username: any = 'username';
  password: string = 'password';
  gpsrequired: any;
  base!: any;
  code: any;
  nbr!: number;
  data: Data = new Data();
  photo: any[] = [];
  tabs: any = -1;
  sites: any[] = [];

  ngOnInit(): void {
    this.username = localStorage.getItem('username');
    this.gpsrequired = localStorage.getItem('gpsrequired');

    this.stateCtrl = new FormControl();
    this.stateCtrl2 = new FormControl();
    this.filteredStates = this.stateCtrl.valueChanges.pipe(
      startWith(''),
      map((state) => (state ? this.filterStates(state) : this.states.slice()))
    );

    this.filteredStates2 = this.stateCtrl2.valueChanges.pipe(
      startWith(''),
      map((state2) =>
        state2 ? this.filterStates2(state2) : this.states2.slice()
      )
    );
  }

  filterStates(name: string) {
    return this.states.filter((state) => state.toString().indexOf(name) === 0);
  }

  filterStates2(name2: string) {
    return this.states2.filter(
      (state2) => state2 !== null && state2.toString().indexOf(name2) === 0
    );
  }

  selectedFiles?: FileList;
  onChangeFile($event: any, i: number) {
    this.selectedFiles = $event.target.files;
    if (this.selectedFiles && this.selectedFiles?.length > 0)
      this.photo[i] = this.selectedFiles[0];
    // this.selectedFiles = event.target.files;
  }

  newdas = {
    binf: '',
    bsup: '',
    dbride: '',
    diagonales: {
      H: '5.6',
      b: '4.5',
      diametre: '',
      epaisseur: '',
      longueur: '',
      materiau: 'S355',
      tube: 'true',
    },
    drepartition: '',
    dtiges: '',
    ebride: '',
    mat_boulon: '',
    mat_bride: '',
    mat_plaque: '',
    mat_tiges: '',
    membrures: {
      H: '',
      b: '',
      diametre: '',
      epaisseur: '',
      longueur: '',
      materiau: 'S355',
      tube: 'true',
    },
    nb_boulons: '',
    nb_tiges: '',
    numero: 1,
    traverses: {
      H: '',
      b: '',
      diametre: '',
      epaisseur: '',
      longueur: '',
      materiau: 'S355',
      tube: 'true',
    },
    zinf: '',
    zsup: '',
  };

  downloadFile(doc: Blob, name: any): void {
    //this.documentService.pullById(doc.id).subscribe(blob => saveAs(blob, doc.name));
    saveAs(doc, name);
  }
  newnbr!: number;
  editnbr() {}

  getNbr() {
    if (!this.code) {
      Swal.fire({
        title: 'Code site est invalide.',
        icon: 'error',
        width: 300,
        padding: '3em',
      });
      return;
    }
    this.clearcache();
    //this.gps = false
    this.methode = false;
    //this.methode=""
    this.selectmethode = false;
    this.s = false;
    this.msg = '';
    this.images = [];
    this.tabs = -1;
    this.run = true;
    if (!this.gps) {
      this.longitude = null;
      this.latitude = null;
    }

    this.siteService
      .getInfoSites(this.code, this.latitude, this.longitude)
      .subscribe((data: any) => {
        var newobject: any[] = data.data.formulaire_detaillé.trancons;

        var oldnumber: any = data.nombre_troncon;
        data.nombre_troncon = oldnumber;
        if (data.nombre_troncon > 0) this.newnbr = oldnumber;

        var tranclist: any = data.data.formulaire_detaillé.trancons;

        /*for(var i:number=0;i<data.nombre_troncon;i++){
        if(tranclist[i]==null)
       tranclist[i]=_.cloneDeep(this.newdas);
       tranclist[i].numero=this.newnbr-i
      }*/

        data.data.formulaire_detaillé.trancons = tranclist;

        const dialogRef = this.dialog.open(this.validation, {
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.selectimage();
          this.photoService.init();
          this.selectmethode = true;
          this.showMethode = true;
          if (
            this.newnbr != data.nombre_troncon &&
            this.newnbr >= data.nombre_troncon
          ) {
            var diff = this.newnbr - data.nombre_troncon;
            var lst: Trancons[] = [];
            var sd: any[] = [];

            lst = data.data.formulaire_detaillé.trancons;
            lst.length = data.nombre_troncon;
            for (var i: number = 1; i <= diff; i++) {
              var tr: any = _.cloneDeep(this.newdas);
              tr.numero = parseInt(data.nombre_troncon + i);
              lst.push(tr);
            }

            lst = this.casNumber(lst);
            this.data.trancons = lst;
            data.data.formulaire_simplifie = sd;
            data.data.trancons = lst;
            data.data.formulaire_detaillé.trancons = lst;
            data.nombre_troncon = this.newnbr;
          } else {
            lst = data.data.formulaire_detaillé.trancons;
            lst = this.casNumber(lst);
            data.data.formulaire_detaillé.trancons = lst;
            lst.length = this.newnbr;
            data.nombre_troncon = this.newnbr;
          }

          this.nbr = data.nombre_troncon;
          this.hauteur = data.hauteur + ' (m)';
          this.adress = data.adresse;
          this.department = data.departement;
          this.base = this.nbr;
          this.s = true;

          if (data && data.data && data.data.formulaire_simplifie) {
            this.sdatas = data.data.formulaire_simplifie;
          }

          if (data && data.data && data.data.formulaire_detaillé) {
            this.data = data.data.formulaire_detaillé;
          } else {
            var lst: Trancons[] = [];
            for (let r = 0; r < this.nbr; r++) {
              //this.photo.push()//(src+r+'.png')
              tr = new Trancons();
              tr.numero = this.nbr - r;
              tr.diagonales = new Dimension();
              tr.membrures = new Dimension();
              tr.traverses = new Dimension();
              lst[r] = tr;
            }
            this.data.trancons = lst;
          }
        });

        this.codesite = data.code_site;
        //data.data.formulaire_detaillé.trancons.reverse()
      });

    this.siteService
      .getInfoSites(this.code, this.latitude, this.longitude)
      .subscribe((data: any) => {});
  }

  validaterow: any[] = [];
  openAttr(atr: any, n: number) {
    var i: number = n - 1;
    var list: any;
    if (atr == 'mat_tiges') list = this.mattiges;
    if (atr == 'mat_plaque') list = this.matplaque;
    if (atr == 'mat_boulon') list = this.mattiges;
    if (atr == 'mat_bride') list = this.matplaque;

    const dialogRef = this.dialog.open(SimpleAttComponent, {
      data: {
        atr: atr,
        type: 'atr',
        val: this.data.trancons[i][atr],
        list: list,
        newobj: this.validaterow,
        pp: n,
        codesite: this.codesite,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{

      this.sdatas.push(result.data);
      if (result) this.data.trancons[i][atr] = result.data;
    });
  }

  openTable(atr: any, n: number) {
    var i: number = n - 1;

    const dialogRef = this.dialog.open(SimpleAttComponent, {
      data: {
        atr: atr,
        type: 'table',
        val: this.data.trancons[i][atr],
        newobj: this.validaterow,
        pp: n,
        codesite: this.codesite,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{

      //this.sdatas.push(result.data)
      if (result) this.data.trancons[i][atr] = result.data;
      if (this.photoService.photos[0]?.img) {
        var ph = new UserPhoto();
        var nbr = i + 1;
        ph.name = atr + '_' + nbr;
        ph.type = 'png';
        ph.img = this.photoService.photos[0]?.img;
        // this.downloadFile(ph.img!,ph.name)
        this.images.push(ph);
      }
    });
  }

  onChangeDiagonales($event: any, row: any, col: any, i: number) {
    this.data.trancons[i][col] = $event.target.value;
  }

  validerBase() {
    if (this.hms && this.lf && this.hf && this.h && this.a && this.b) {
      var msg: any = '';
      if (!this.data.hms) msg += ' <br> ' + 'Hms';
      if (!this.data.lf) msg += ' <br> ' + 'Lf';
      if (!this.data.hf) msg += ' <br> ' + 'Hf';
      if (!this.data.h) msg += ' <br> ' + 'H';
      if (!this.data.a) msg += ' <br> ' + 'A';
      if (!this.data.b) msg += ' <br> ' + 'B';

      if (msg && msg.length > 0)
        // Swal.fire('Erreur','Merci de renseigner ces éléments:'+msg,'error')
        Swal.fire({
          title: 'Merci de renseigner ces éléments: ' + msg,
          icon: 'warning',
          width: 400,
          padding: '3em',
        });
      else {
        var partailBase: any = {
          a: this.data.a,
          b: this.data.b,
          commentaire: this.data.commentaire,
          els: this.data.els,
          elu: this.data.elu,
          h: this.data.h,
          hf: this.data.hf,
          hms: this.data.hms,
          lf: this.data.lf,
        };
        this.siteService
          .postPartialDetail(this.code, partailBase)
          .subscribe((data) => {});
        this.data.valideBase = true;
        this.tabs = -1;
        //  Swal.fire('Fondation validée ','','success')
        Swal.fire({
          title: 'Fondation validée',
          icon: 'success',
          width: 400,
          padding: '3em',
        });
      }
    } else {
      Swal.fire({
        title: 'Tous les champs marqués par * doivent être validés',
        icon: 'error',
        width: 400,
        padding: '3em',
      });
    }
  }

  valider() {
    // this.data.trancons.length-=1
    delete this.data?.hms;
    delete this.data?.lf;
    delete this.data?.hf;
    delete this.data?.h;
    delete this.data?.a;
    delete this.data?.b;
    delete this.data?.elu;
    delete this.data?.els;
    delete this.data?.valideBase;

    var err: string = '';
    var conf: string = '';
    var env: boolean = false;
    if (this.methode == 'detail') {


      for (var i: number = 0; i < this.data.trancons.length; i++) {
        var nbr = this.data.trancons.length - i;
        if (!this.data.trancons[i].valide)
          err = err + " <h1 style='margin-left: auto;'>" + 'T' + nbr + '</h1>';
      }
      if (err && err.length > 0) {
        env = true;
        //Swal.fire('Erreur','Vous devez valider:'+err,'error')
        Swal.fire({
          title: 'Merci de valider :' + err,
          icon: 'error',
          width: 300,
          padding: '3em',
        });
      } else
        this.siteService
          .postDetail(this.code, this.data, this.images)
          .subscribe(
            (val: any) => {
              conf = 'Formulaire détaillé envoyé avec succès';
              this.data.valide = true;

              this.validated = true;
            },
            (error: { error: { message: any } }) => {
              //this.loginError=true;

              err = 'Erreur au niveau de serveur:' + error.error.message;
              //  Swal.fire('Erreur','','error')
            }
          );
    } else if (this.methode == 'simple') {
      this.siteService.setSimple(this.code, this.sdatas, this.photo).subscribe(
        (val) => {
          conf = 'Formulaire simplifié envoyé avec succès';
          //this.methode=""
        },
        (error) => {
          //this.loginError=true;

          err = 'Erreur au niveau de serveur:' + error.error.message;
        }
      );
    }
    if (!env) {
      if (err && err.length > 0)
        //Swal.fire('Erreur',err,'error')
        Swal.fire({
          title: err,
          icon: 'error',
          width: 300,
          padding: '3em',
        });
      else if (conf && conf.length > 0)
        // Swal.fire('',conf,'success')
        Swal.fire({
          title: conf,
          icon: 'success',
          width: 300,
          padding: '3em',
        });
    }
  }

  openSimpleData() {
    const dialogRef = this.dialog.open(SimpleDataComponent, {
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{

      if (result) this.sdatas.push(result.sdata);
    });
  }

  openDialogEdit(row: any) {
    const dialogRef = this.dialog.open(SimpleDataComponent, {
      data: { val: row },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      // this.consultantservice.getConsultantList().subscribe((data:any)=>{
    });
  }
  changeFile($event: any) {}
  upload() {}
  visibilityy: boolean = false;
  s: boolean = false;
  vue: boolean = true;
  showBase: boolean = false;
  visibility() {
    // this.visibilityy=true
    //  this.visibilityy=!this.visibilityy
  }
  onchangeModel(pp: any, $event: any) {
    //
    this.vue = true;
    this.tabs = pp;
    this.vue = true;
    this.vueIndx = true;
    this.pp = pp;
    if (pp == -1) {
      this.showBase = true;
    }
  }

  pylone() {
    this.tabs = -1;
  }
  infos() {
    this.tabs = -2;
  }
  msg: any;
  addPhotoToGallery() {
    this.photoService.addNewToGallery();
    this.msg = 'Image chargée';
  }
  photoPylone: any;

  openPhotoBr(n: number) {
    var i: number = n - 1;
    this.photoService.init();
    const dialogRef = this.dialog.open(PhotoComponent, {});
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {
        if (this.photoService.photos[0]?.img) {
          var ph = new UserPhoto();
          var nbr = i + 1;
          ph.name = 'bride_' + nbr;
          ph.type = 'png';
          ph.img = this.photoService.photos[0]?.img;
          // this.downloadFile(ph.img!,ph.name)
          this.images.push(ph);
        }
      }
    });
  }
  openPhotoTr(n: number) {
    var i: number = n - 1;
    this.photoService.init();
    const dialogRef = this.dialog.open(PhotoComponent, {});
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {
        if (this.photoService.photos[0]?.img) {
          var ph = new UserPhoto();
          var nbr = i + 1;
          ph.name = 'troncon_' + nbr;
          ph.type = 'png';
          ph.img = this.photoService.photos[0]?.img;
          // this.downloadFile(ph.img!,ph.name)
          this.images.push(ph);
        }
      }
    });
  }
  openPhoto() {
    this.photoService.init();
    const dialogRef = this.dialog.open(PhotoComponent, {});
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {
        if (this.photoService.photos[0]?.img) {
          var ph = new UserPhoto();
          ph.name = 'site';
          ph.type = 'png';
          ph.img = this.photoService.photos[0]?.img;
          // this.downloadFile(ph.img!,ph.name)
          this.images.push(ph);
        }
      }
    });
  }

  openPhotoBase() {
    this.photoService.init();
    const dialogRef = this.dialog.open(PhotoComponent, {});
    dialogRef.afterClosed().subscribe((result: any) => {
      if (this.photoService.photos[0]) {
        var ph = new UserPhoto();

        ph.name = 'base';
        ph.type = 'png';
        ph.img = this.photoService.photos[0]?.img;
        // this.downloadFile(ph.img!,ph.name)
        this.images.push(ph);
      }
    });
  }
  validerTr(i: number) {
    //var i:number=n-1

    var msg: any = '';
    if (!this.data.trancons[i]?.dtiges)
      msg =
        " <br> <h1 style='display: inline-flex;margin-left: auto;'>" +
        'Dtiges' +
        '</h1>';
    if (!this.data.trancons[i]?.dbride)
      msg =
        msg +
        " <br> <h1 style='display: inline-flex;margin-left: auto;'>" +
        'Dbride' +
        '</h1>';

    if (!this.data.trancons[i]?.ebride)
      msg =
        msg +
        " <br> <h1 style='display: inline-flex;margin-left: auto;'>" +
        'Ebride' +
        '</h1>';
    if (this.codesite != 'FR-MQ-1056') {
      if (!this.data.trancons[i]?.diagonales.materiau)
        msg =
          msg +
          " <br> <h1 style='display: inline-flex;margin-left: auto;'>" +
          'Matériaux de diagonale' +
          '</h1>';
      if (!this.data.trancons[i]?.traverses.materiau)
        msg =
          msg +
          " <br> <h1 style='display: inline-flex;margin-left: auto;'>" +
          'Matériaux de traverses' +
          '</h1>';
      if (!this.data.trancons[i]?.diagonales.epaisseur)
        msg =
          msg +
          " <br> <h1 style='display: inline-flex;margin-left: auto;'> Epaisseur de diagonale" +
          '</h1>';
      if (!this.data.trancons[i]?.traverses.epaisseur)
        msg =
          msg +
          " <br> <h1 style='display: inline-flex;margin-left: auto;'> Epaisseur de traverses" +
          '</h1>';
      if (
        this.data.trancons[i]?.diagonales.tube == true &&
        !this.data.trancons[i]?.diagonales.diametre
      )
        msg =
          msg +
          " <br> <h1 style='display: inline-flex;margin-left: auto;'> Diamètre de diagonale" +
          '</h1>';
      if (
        this.data.trancons[i]?.traverses.tube == true &&
        !this.data.trancons[i]?.traverses.diametre
      )
        msg =
          msg +
          "<br> <h1 style='display: inline-flex;margin-left: auto;'> Diamètre de traverses" +
          '</h1>';
    }
    if (!this.data.trancons[i]?.membrures.materiau)
      msg =
        msg +
        " <br> <h1 style='display: inline-flex;margin-left: auto;'>" +
        'Matériaux de membrures' +
        '</h1>';

    if (!this.data.trancons[i]?.membrures.epaisseur)
      msg =
        msg +
        " <br> <h1 style='display: inline-flex;margin-left: auto;'> Epaisseur de membrures" +
        '</h1>';

    if (
      this.data.trancons[i]?.membrures.tube == true &&
      !this.data.trancons[i]?.membrures.diametre
    )
      msg =
        msg +
        "<br> <h1 style='display: inline-flex;margin-left: auto;'> Diamètre de membrures" +
        '</h1>';

    if (msg && msg.length > 1)
      // Swal.fire('Erreur','Merci de renseigner ces éléments:'+msg,'error')
      Swal.fire({
        title: 'Merci de renseigner ces éléments:' + msg,
        icon: 'error',
        width: 300,
        padding: '3em',
      });
    else {
      this.data.trancons[this.pp - 1].valide = true;
      Swal.fire('Tronçon validé ', '', 'success');

      this.tabs = -1;
    }
  }
  selectimage() {
    this.image = [];
    this.style = [];
    this.class = [];

    var j = 1;
    var c2 = 2;
    var c = 'tr{margin-left:';
    var c1 = '%;}';
    if (this.codesite == 'FR-MQ-1056') {
      var s = '../../assets/img/mono';
      var b = 200;
    } else {
      var s = '../../assets/img/P_';
      var b = 200;
    }
    for (var i: number = 1; i <= this.newnbr; i++) {
      var s1 = '.png';
      var a = 'width: ';
      var a2 = 'px;';
      this.image.push(s + j + s1);
      this.style.push(b);
      this.class.push(c + c2 + c1);
      c2 += 2;
      b = b * 0.855;
      j++;
      if (j == 5) {
        j = 1;
      }
    }
    this.style.reverse();
  }

  onChangeMethode($event: any) {
    this.methode = $event.target.value;

    this.showMethode = true;
  }
  vuDiag: boolean = false;
  vuTraf: boolean = false;

  index: any;
  vueIndx: boolean = false;

  openStepper(i: any) {
    this.tabs = i;
    this.vue = true;
    this.index = i;
    this.vueIndx = true;

    if (i == -2) {
      this.showBase = true;
    }
    if (i > -1) {
      //this.pp=i
      this.tabs = i;
    }
  }
  retour() {
    this.vue = true;
    this.vueIndx = false;
  }

  name = 'Angular ' + VERSION.major;
  isZoomed = false;
  pos = { top: 0, left: 0, x: 0, y: 0 };
  @ViewChild('img') 'img': ElementRef;
  @ViewChild('container') 'container': ElementRef;
  onClick(e: any) {
    this.isZoomed = !this.isZoomed;
    if (this.isZoomed) {
      this.container.nativeElement.style.overflow = 'hidden';
      this.img.nativeElement.style.width = '200%';
      this.img.nativeElement.style.cursor = 'zoom-out';
      this.img.nativeElement.style.cursor = 'zoom-out';
      this.img.nativeElement.style.left = `-${e.clientX}`;
      this.img.nativeElement.style.top = `-${e.clientY}`;
    } else {
      this.container.nativeElement.style.overflow = 'hidden';
      this.img.nativeElement.style.width = '100%';
      this.img.nativeElement.style.cursor = 'zoom-in';
    }
  }
  onMouseDown(e: any) {
    this.pos = {
      // The current scroll
      left: this.container.nativeElement.scrollLeft,
      top: this.container.nativeElement.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };
  }

  mouseMoveHandler(e: any) {
    // How far the mouse has been moved
    const dx = (e.clientX - this.pos.x) * 2;
    const dy = (e.clientY - this.pos.y) * 3;

    // Scroll the element
    this.container.nativeElement.scrollTop = this.pos.top - dy;
    this.container.nativeElement.scrollLeft = this.pos.left - dx;
  }

  onLeave() {
    this.container.nativeElement.style.overflow = 'hidden';
    this.img.nativeElement.style.transform = 'scale(1)';
    this.img.nativeElement.style.cursor = 'zoom-in';
  }

  x: any; //= document.getElementById("demo");
  gps: boolean = false;

  getLocation() {
    this.methode = null;
    this.clearcache();
    this.code = null;
    this.selectmethode = false;
    this.s = false;
    /* if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(this.showPosition);
       
 
       
     } */
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        var lat = position?.coords?.latitude;
        //  this.latitude=lat

        var long = position?.coords?.longitude;
        //this.longitude= long;

        this.getNbrByCoord(lat, long);
        if (!isNaN(long) && !isNaN(lat)) {
          this.gps = true;
          this.run = true;
          this.longitude = long;
          this.latitude = lat;
        }
      });
    }
  }

  showPosition(position: any) {
    var lat = position?.coords?.latitude;
    //  this.latitude=lat

    var long = position?.coords?.longitude;
    //this.longitude= long;

    // this.getNbrByCoord(lat,long)
    this.msg = '';
    this.images = [];
    this.tabs = -1;
    this.run = true;

    this.siteService
      .getInfoSites(this.code, lat, long)
      .subscribe((data: any) => {
        this.photoService.init();

        this.showMethode = true;
        this.nbr = data.nombre_troncon;
        this.hauteur = data.hauteur + ' (m)';
        this.base = this.nbr;
        this.s = true;

        if (data && data.data && data.data.formulaire_simplifie) {
          this.sdatas = data.data.formulaire_simplifie;
        }

        if (data && data.data && data.data.formulaire_detaillé) {
          this.data = data.data.formulaire_detaillé;
        } else {
          var lst: Trancons[] = [];
          for (let r = 0; r < this.nbr; r++) {
            //this.photo.push()//(src+r+'.png')
            var tr = new Trancons();
            tr.numero = this.nbr - r;
            tr.diagonales = new Dimension();
            tr.membrures = new Dimension();
            tr.traverses = new Dimension();
            lst[r] = tr;
          }
          this.data.trancons = lst;
        }
      });
  }

  getNbrByCoord(lat: any, long: any) {
    //this.methode=""
    this.msg = '';
    this.images = [];
    this.tabs = -1;
    this.run = true;

    this.siteService
      .getInfoSites(this.code, lat, long)
      .subscribe((data: any) => {
        this.newnbr = data.nombre_troncon;

        var tranclist: any = data.data.formulaire_detaillé.trancons;

        for (var i: number = 0; i < data.nombre_troncon; i++) {
          if (tranclist[i] == null) tranclist[i] = _.cloneDeep(this.newdas);
          tranclist[i].numero = parseInt(tranclist[i].numero) + i;
        }

        data.data.formulaire_detaillé.trancons = tranclist;

        const dialogRef = this.dialog.open(this.validation, {
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.photoService.init();
          this.selectmethode = true;
          if (
            this.newnbr != data.nombre_troncon &&
            this.newnbr >= data.nombre_troncon
          ) {
            var diff = this.newnbr - data.nombre_troncon;
            var lst: Trancons[] = [];
            var sd: any[] = [];

            lst = data.data.formulaire_detaillé.trancons;
            lst.length = data.nombre_troncon;
            for (var i: number = 1; i <= diff; i++) {
              var tr: any = _.cloneDeep(this.newdas);
              tr.numero = parseInt(data.nombre_troncon + i);
              lst.push(tr);
            }

            this.data.trancons = lst;
            data.data.formulaire_simplifie = sd;
            data.data.trancons = lst;
            data.data.formulaire_detaillé.trancons = lst;
            data.nombre_troncon = this.newnbr;
          } else {
            lst = data.data.formulaire_detaillé.trancons;
            lst.length = this.newnbr;
            data.nombre_troncon = this.newnbr;
          }

          this.showMethode = true;
          this.nbr = data.nombre_troncon;
          this.hauteur = data.hauteur + ' (m)';
          this.base = this.nbr;
          this.adress = data.adresse;
          this.department = data.departement;

          this.s = true;
          this.code = data.code_site;

          if (data && data.data && data.data.formulaire_simplifie) {
            this.sdatas = data.data.formulaire_simplifie;
          }

          if (data && data.data && data.data.formulaire_detaillé) {
            this.data = data.data.formulaire_detaillé;
          } else {
            var lst: Trancons[] = [];
            for (let r = 0; r < this.nbr; r++) {
              //this.photo.push()//(src+r+'.png')
              tr = new Trancons();
              tr.numero = this.nbr - r;
              tr.diagonales = new Dimension();
              tr.membrures = new Dimension();
              tr.traverses = new Dimension();
              lst[r] = tr;
            }
            this.data.trancons = lst;
          }
        });
      });
  }

  allvalid(data: any): boolean {
    return true;
  }

  clearcache() {
    //this.image=[]
    this.run = false;
    this.sdatas = [];
    this.images = [];
    this.selectmethode = false;
    this.sites = [];
    this.newnbr = NaN;
    this.hms=false
    this.h=false
    this.a=false
    this.b=false
    this.elu=false
    this.els=false
    this.hf=false
    this.lf=false
    this.data=new Data()
    this.validaterow=[]
    this.sdatas=[]
  }

  //if gpsrequired buttons behavior
  gpscodesite() {
    this.methode = null;
    this.clearcache();
    this.code = null;
    this.selectmethode = false;
    this.s = false;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        var lat = position?.coords?.latitude;
        this.latitude = lat;

        var long = position?.coords?.longitude;
        this.longitude = long;

        this.siteService
          .getInfoSites(this.code, lat, long)
          .subscribe((data: any) => {
            this.code = data.code_site;
            if (!isNaN(long) && !isNaN(lat)) {
              this.gps = true;
              this.run = true;
              this.longitude = long;
              this.latitude = lat;
            }
          });
      });
    }
  }

  validergps() {
    if (!this.code) {
      Swal.fire({
        title: 'Code site est invalide.',
        icon: 'error',
        width: 300,
        padding: '3em',
      });
      return;
    }
    this.msg = '';
    this.images = [];
    this.tabs = -1;
    this.run = true;
    this.siteService
      .getInfoSites(this.code, null, null)
      .subscribe((data: any) => {
        this.newnbr = data.nombre_troncon;
        const dialogRef = this.dialog.open(this.validation, {
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.photoService.init();
          this.selectmethode = true;
          if (
            this.newnbr != data.nombre_troncon &&
            this.newnbr >= data.nombre_troncon
          ) {
            var diff = this.newnbr - data.nombre_troncon;
            var lst: Trancons[] = [];
            var sd: any[] = [];

            lst = data.data.formulaire_detaillé.trancons;
            lst.length = data.nombre_troncon;
            for (var i: number = 1; i <= diff; i++) {
              var tr: any = _.cloneDeep(this.newdas);
              tr.numero = parseInt(data.nombre_troncon + i);
              lst.push(tr);
            }

            this.data.trancons = lst;
            data.data.formulaire_simplifie = sd;
            data.data.trancons = lst;
            data.data.formulaire_detaillé.trancons = lst;
            data.nombre_troncon = this.newnbr;
          } else {
            lst = data.data.formulaire_detaillé.trancons;
            lst.length = this.newnbr;
            data.nombre_troncon = this.newnbr;
          }

          this.showMethode = true;
          this.nbr = data.nombre_troncon;
          this.hauteur = data.hauteur + ' (m)';
          this.base = this.nbr;
          this.adress = data.adresse;
          this.department = data.departement;

          this.s = true;
          this.code = data.code_site;

          if (data && data.data && data.data.formulaire_simplifie) {
            this.sdatas = data.data.formulaire_simplifie;
          }

          if (data && data.data && data.data.formulaire_detaillé) {
            this.data = data.data.formulaire_detaillé;
          } else {
            var lst: Trancons[] = [];
            for (let r: number = 0; r < this.nbr; r++) {
              //this.photo.push()//(src+r+'.png')
              tr = new Trancons();
              tr.numero = this.nbr - r;
              tr.diagonales = new Dimension();
              tr.membrures = new Dimension();
              tr.traverses = new Dimension();
              lst[r] = tr;
            }
            this.data.trancons = lst;
          }
        });
      });
  }

  testgps() {
    this.gps = false;
    this.run = false;
    this.selectmethode = false;
    this.methode = false;
    this.s = false;
  }

  async changecode() {
    this.nom = '';
    var i: number = 0;
    for (var line of this.states) {
      if (line == this.code) {
        this.nom = this.states2[i];
      } else {
        i++;
      }
    }
  }

  async changenom() {
    this.code = '';
    var i: number = 0;
    for (var line of this.states2) {
      if (line == this.nom) {
        this.code = this.states[i];
      } else {
        i++;
      }
    }
  }

  casNumber(lst: any): any {
    for (var line of lst) {
      line.numero = Number(line.numero);
    }
    return lst;
  }
}
