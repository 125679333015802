import { Dimension } from "./dimension";

export class Trancons {
    membrures:any
    diagonales:any
    traverses: any
    numero: number=1
    zsup: any= 3.2
    zinf : any=11.2
    bsup :any= 20.3
    binf :any= 60.2
    dtiges:number=1
    dbride:number=2
    drepartition:number=3
    ebride:number=4
    nb_tiges:number=5
    materiau:any
    mat_tiges:any= 4.6
    mat_plaque:any= 'S355'
    mat_boulon:any= 4.6
    mat_bride:any= 'S355'
    nb_boulons:number= 15
    commentaire:any
    valide:Boolean=false
}
