export class Data {
    
        trancons:any|undefined ;//,
        hms:any;// 3,
        lf:any ;// 2,
        hf:any;// 3,
        h:any ;// 4,
        a:any ;// 5,
        b:any ;// 6
        elu:any ;
        els:any ;
        commentaire:any ;
        valideBase:any=false;
        valide:any




       
      
}
